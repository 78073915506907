import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {declOfNum} from '../../../../utils/commons';
import {ChatsService} from '../../../../services/chats.service';
import {ConfigService} from '../../../../services/config.service';
import {JSON} from 'ta-json';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-chat-members-menu',
  templateUrl: './chat-members-menu.component.html',
  styleUrls: ['./chat-members-menu.component.scss']
})
export class ChatMembersMenuComponent implements OnInit, OnChanges, OnDestroy {
  /** триггер завершения подписок */
  private destroyed = new Subject<void>();
  @Input() public members = [];
  @Input() public notify = false;
  @Input() public type = null;
  public leads = [];
  public guests = [];
  public cnt = '';
  public membersTitle = 'УЧАСТНИКИ ЗАДАЧИ';

  public TITLES = {
    'task_manager': 'УЧАСТНИКИ ЗАДАЧИ',
    'function':  'УЧАСТНИКИ ЗАДАЧИ',
    'matrix': 'УЧАСТНИКИ МАТРИЦЫ',
    'motivation_with_user' : 'УЧАСТНИКИ МОТИВАЦИИ',
    'motivation_without_user': 'УЧАСТНИКИ МОТИВАЦИИ',
    'motivation': 'УЧАСТНИКИ МОТИВАЦИИ'
  };
  private timedOutCloser;

  constructor(
      private chatService: ChatsService,
      private config: ConfigService,
      private auth: AuthService
  ) {
  }

  isMe(id) {
    return +this.auth.currentUser$.value.id == +id;
  }

  canLeave() {
    const selfId = +this.auth.currentUser$.value.id;
      const isMeLead = this.leads.some(el => +el.id == selfId);
      const isMeGuest = this.guests.some(el => +el.id == selfId);
    return isMeGuest && !isMeLead;
  }

  ngOnInit(): void {
    this.membersTitle =  this.TITLES[this.type];

    this.chatService.members$
        .pipe(takeUntil(this.destroyed))
        .subscribe(res => {
          if (res) {
            this.members = res;
            this.cnt = this.members.length + declOfNum(this.members.length, [' участник', ' участника', ' участников']);
            this.sortUsers();
          } else {
            this.cnt = '';
            this.leads = [];
            this.guests = [];
          }
        });

    this.chatService.notify$
        .pipe(takeUntil(this.destroyed))
        .subscribe(res => {
          if (res !== null) {
            this.notify = res;
          }
        });

  }

  onNotifyClick() {
    this.chatService.onChatNotifyClick$.next(!this.notify);
  }

  getUserAvatar(user) {
    return this.config.getUserAvatarByUser(user, 'small');
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  sortUsers() {
    this.leads = this.members.filter(el => !el.removable);
    this.guests = this.members.filter(el => el.removable);
  }

  name(user) {
    return user.last_name + ' ' + user.first_name;
  }

  addUserClick() {
    this.chatService.onAddUserClick$.next(true);
  }

  exitClick() {
    this.chatService.onLeaveChatClick$.next(true);
  }


  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.chatService.notify$.next(null);
    this.chatService.members$.next(null);
  }

  protected readonly onmouseleave = onmouseleave;

  mouseEnter(trigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 400);
  }
}
