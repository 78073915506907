import { BehaviorSubject, Observable, Subject, takeUntil, tap } from 'rxjs';
import { AcademyUserRights, Rights, User } from 'src/app/models/user';

import { Injectable } from '@angular/core';

import { Question } from '../models/question';
import { TestAcademy } from '../models/test-academy';
import { Topic } from '../models/topic';
import { UserTestAssignmentsByDepartment } from '../models/user-test-assignment';
import { ApiAcademyService } from './api-academy.service';

@Injectable({
    providedIn: 'root',
})
export class AcademyService {
    /**
     * Права пользователя внутри модуля академии
     */
    currentUserRights$: BehaviorSubject<AcademyUserRights> =
        new BehaviorSubject<AcademyUserRights>({
            is_academy_administrator: false,
            is_company_administrator: false,
            is_manager: false,
            is_platform_owner_administrator: false,
            managed_departments_ids: [],
        });
    topics$: BehaviorSubject<Topic[]> = new BehaviorSubject(null);
    tests$: BehaviorSubject<TestAcademy[]> = new BehaviorSubject([]);
    selectedTests$: BehaviorSubject<TestAcademy> = new BehaviorSubject(null);
    actualQuestions$: BehaviorSubject<Question[]> = new BehaviorSubject([]);
    currentUser$: BehaviorSubject<any> = new BehaviorSubject(null);
    allUsers$: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedUsers$: BehaviorSubject<UserTestAssignmentsByDepartment[]> =
        new BehaviorSubject([]);
    divisions$: BehaviorSubject<any> = new BehaviorSubject(null);
    topicsMaterialSubject = new BehaviorSubject<Topic[]>([]);
    topicsMaterial$ = this.topicsMaterialSubject.asObservable();
    questions$: BehaviorSubject<any> = new BehaviorSubject(null);
    currentQuestionStatistics$ = new BehaviorSubject<any>([]);
    chosenTestInfoId$ = new BehaviorSubject<number>(null);
    chosenTestInfo$ = new BehaviorSubject<number>(null);
    isQuestionOpen$ = new BehaviorSubject<boolean>(false);
    departmentsStatistics$ = new BehaviorSubject<any>([]);
    counterOfNotViewedTests$ = new BehaviorSubject<any>(0);

    destroyed$: Subject<void> = new Subject();

    get isCurrentUserAdmin(): boolean {
        const rights = this.currentUserRights$.value;
        return rights.is_platform_owner_administrator;
    }

    setTopics(topics: Topic[]) {
        this.topics$.next(topics);
    }

    setTopicsMaterial(topicsMaterial: Topic[]): void {
        this.topicsMaterialSubject.next(topicsMaterial);
    }

    saveQuestion(question: Question): void {
        this.questions$.next(question);
    }

    setAssignmentsCounter(assignments: any[]) {
        this.counterOfNotViewedTests$.next(
            assignments.reduce((accumulator, currentValue) => {
                if (!currentValue.is_viewed) {
                    accumulator += 1;
                }
                return accumulator;
            }, 0),
        );
    }
}
