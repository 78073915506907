import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import {registerLocaleData} from '@angular/common';
import {Component} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

registerLocaleData(localeRu, 'ru-RU', localeRuExtra);

const RADIUS_ICONS = [
    'radius_cake', 'radius_home', 'radius_business_trip', 'radius_vacation', 'radius_hospital', 'radius_pregnant',
    'radius_room', 'radius_circle', 'radius_list', 'radius_kanban', 'radius_planing', 'radius_hierarchy', 'radius_module',
    'radius_settings', 'radius_tune', 'radius_mail', 'radius_archive',
    'radius_skype', 'radius_info', 'radius_archive', 'radius_star-border', 'radius_star-filled', 'radius_star-blue-filled', 'radius_filters',
    'radius_alarm', 'radius_bookmark-remove', 'radius_bookmark_border', 'radius_expand', 'radius_people', 'radius_delete_photo',
    'radius_gantt', 'radius_clear', 'radius_main_gmail', 'radius_event-busy', 'radius_link', 'radius_recover', 'radius_finance',
    'radius_task', 'radius_calendar', 'radius_loader', 'radius_delete', 'radius_book-open', 'radius_lock-open', 'radius_add_to_drive',
    'radius_google_spreadsheet', 'radius_google_presentation', 'radius_google_doc', 'radius_edit', 'radius_task-control',
    'radius_phonebook', 'radius_chat', 'radius_two-people', 'radius_one_chat', 'radius_question', 'radius_bell', 'radius_bell_off',
    'radius_arrow_up', 'radius_done_green', 'radius_rating', 'radius_right', 'radius_plus', 'radius_delete_fill',
    'radius_done', 'radius_delete_outline', 'radius_folder', 'radius_company', 'radius_select_arrow',
    'radius_telegram_color', 'radius_add', 'radius_import', 'format_list_bulleted', 'radius_question_small', 'radius_bug',
    'radius_blocked', 'radius_importance1', 'radius_importance2', 'radius_importance3', 'radius_quiz', 'radius_telegram',
    'radius_pay_card', 'radius_pay_qr', 'radius_pay_invoice', 'radius_bug_red', 'radius_deal', 'radius_exchange', 'radius_circles',
    'radius_mask', 'radius_matrix', 'radius_list2', 'radius_eclipse', 'radius_position', 'radius_beta', 'radius_main',
    'radius_phone_connect', 'radius_phone', 'radius_email', 'radius_mobile', 'radius_blue_ok', 'radius_grey_no', 'radius_dots_1',
    'radius_dots_2', 'radius_statistics', 'radius_clock', 'radius_history', 'radius_close_fullscreen', 'radius_sort',
    'radius_download', 'radius_select', 'radius_visibility', 'radius_save', 'radius_bug_yellow', 'radius_strategic_grey',
    'radius_strategic_yellow', 'radius_strategic_red', 'radius_copy', 'radius_create_subtask', 'radius_turn', 'radius_book',
    'radius_btn-task', 'radius_btn-meet', 'radius_arrow_left', 'radius_book-small', 'radius_calendar_toggle',
    'radius_list-burg','radius_sort_time_low', 'radius_sort_time_top', 'radius_sort_prior_top', 'radius_sort_prior_low',
    'radius_hash', 'radius_arrow_downward', 'radius_arrow_upward', 'radius_lock',
    'radius_sort_status_top', 'radius_sort_status_low','radius_editor_button', 'radius_checklist', 'radius_description', 'radius_dod',
    'radius_actual_result', 'radius_date_start', 'radius_date_expiration', 'radius_assessment', 'radius_files', 'radius_group',
    'radius_tags', 'radius_sprint', 'radius_parent', 'radius_children', 'radius_visibility_off', 'radius_close', 'radius_more',
    'radius_note', 'radius_person_add', 'radius_gift', 'radius_add_task', 'radius_add_parent', 'radius_pin', 'radius_exit',
    'radius_tariff_on', 'radius_tariff_off', 'radius_tariff_on_small', 'radius_tariff_off_small', 'radius_minus', 'radius_learning_book',
    'radius_partner', 'radius_print', 'radius_emoji', 'radius_boards' , 'radius_calendar_small', 'radius_chat_task', 'radius_chat_company',
    'radius_chat_phonebook', 'radius_tagged', 'radius_chat_matrix', 'radius_chat_bell_on', 'radius_chat_bell_off',
    'radius_boards_bold', 'radius_insert_file', 'radius_insert_image', 'radius_progress_activity', 'radius_calendar_start', 'radius_upload',
    'radius_export', 'radius_arrow_right', 'radius_reply', 'radius_matrix_tab_one', 'radius_matrix_tab_quarter', 'radius_matrix_tab_year',
    'radius_matrix_tab_half-year',
];

@Component({
    selector: 'app-component',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        ) {
        RADIUS_ICONS.forEach(el => {
            this.matIconRegistry.addSvgIcon(
                el,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${el}.svg`),
            );
        });
    }
}


