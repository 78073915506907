import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {

    @Input() tooltip = '';
    @Input() icon = null;
    @Input() radiusIcon = null;
    @Input() disabled = false;
    @Input() type = 'button';
    @Output() action = new EventEmitter<any>();
    public isActive = false;
    public isChatRoom = false;

    constructor() {
    }

    @Input() set active(param: boolean) {
        this.isActive = param;
    }

    @Input() set chatRoom(param: boolean) {
        this.isChatRoom = param;
    }

    ngOnInit(): void {
    }

}
