<div class="groups">
    <div class="groups__header">
        <header class="header">
            <h2 class="title">
                {{isTemplate ? 'Группа шаблона' : 'Группа задач'}}
            </h2>

        </header>
    </div>
    <div class="group-box learningGroupTask"
         [ngClass]="{'_disabled': disabled, '_dark': !groupSelected}"
         [ngStyle]="{'background' : getItemColorFromBoard(groupSelected)}"
         [matMenuTriggerFor]="groupsMenu"
         #menu="matMenuTrigger">
        <span class="txt _dark" *ngIf="!groupSelected">
            Без группы
        </span>
        <span class="txt" *ngIf="groupSelected">
            {{ groupSelected.name}}
        </span>
        <mat-icon class="icon" [ngClass]="{'_dark': !groupSelected}" *ngIf="!disabled">expand_more</mat-icon>
    </div>

    <mat-menu #groupsMenu="matMenu" class="menu _long">
        <div class="menu">
            <div class="search-box">
                <app-search-mat class="search"
                                #search
                                (click)="searchClick($event)"
                                [kitView]="true"
                                (textChange)="onSearchChange($event)"></app-search-mat>
            </div>

            <h4 class="menu__title">Выбрать группу</h4>
            <ul class="menu__boards">
                <ng-container *ngFor="let board of sorted">
                    <li class="board" *ngIf="isBoardVisible(board) || (boardGroup(board) || isBoardCurrent(board.id))"
                        [ngClass]="{'_current': isBoardCurrent(board.id), 'disabled': !isBoardCurrent(board.id)}">
                        <h5 class="board__title">
                            <mat-icon class="icon"
                                      *ngIf="board.id == currentBoard.id"
                                      [ngStyle]="{'color' : getBoardColor(board.color)}">bookmark
                            </mat-icon>

                            <mat-icon class="icon" *ngIf="board.id !== currentBoard.id"
                                      [ngStyle]="{'color' : getBoardColor(board.color)}">bookmark_border
                            </mat-icon>
                            <span class="txt">{{ board.name}}</span>
                        </h5>

                        <app-groups-list class="groups-list _task"
                                         [ngClass]="{'_disabled': !canEditGroup(board)}"
                                         [groups]="board.tags"
                                         (click)="onMenuClick($event)"
                                         (onSelect)="select($event, board)"
                                         (removeGroup)="clear($event, board)"
                                         [groupSelected]="getGroupSelected(board)"
                                         [boardId]="board.id"
                                         [selected]="true"
                                         [collapsed]="board['collapsed']"
                                         [shortView]="true"
                                         [canEditGroup]="canEditGroup(board)"
                        ></app-groups-list>


                        <button mat-flat-button
                                *ngIf="isEditMode && canCreateGroup(board) && !board['collapsed']"
                                class="button _transparent learningGroupAddShowTask"
                                [autofocus]="false"
                                type="button"
                                (click)="openGroupAdd(board)">
                            {{ buttonTitle }}
                        </button>

                        <button mat-flat-button
                                *ngIf="!isBoardCurrent(board.id) && board['collapsed']"
                                class="button _transparent learningGroupAddShowTask"
                                [autofocus]="false"
                                type="button"
                                (click)="expand($event, board);">
                            Показать все
                        </button>
                    </li>
                </ng-container>
            </ul>
        </div>
    </mat-menu>
</div>




