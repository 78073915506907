import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TagsService} from '../../../services/tags.service';
import {GroupsService} from '../../../services/groups.service';
import {taskStatusesActualMapping} from '../../../models/task';

@Component({
    selector: 'app-selected-filter-button',
    templateUrl: './selected-filter-button.component.html',
    styleUrls: ['./selected-filter-button.component.scss']
})
export class SelectedFilterButtonComponent implements OnInit {

    @Input() public data = null;
    @Input() disabled = false;
    @Input() public type: string = null;
    @Output() public readonly onClose: EventEmitter<any> = new EventEmitter();

    constructor(
        private tagsService: TagsService,
        private groupsService: GroupsService
    ) {
    }

    ngOnInit(): void {
        if (!this.data && this.type !== 'date') {
            this.onCloseClick();
        }
    }

    get getActualStatusIcon() {
        const status = taskStatusesActualMapping.find(el => el.actionId === this.data?.id);
        return status?.icon;
    }

    getColor() {
        if (this.data.id === 0) {
            return '#4A586D';
        }
        return this.type === 'group' ? '#fff' : '#4A586D';
    }

    getBackgrounColor() {
        if (this.type === 'tag') {
            return this.tagsService.getTagBackground(this.data.color);
        }

        if (this.type === 'group') {
            return this.groupsService.getGroupBackground(this.data.color);
        }

        if (this.type === 'statuses') {
            return;
        }

        return '#D7DFE9';
    }

    onCloseClick() {
        this.onClose.emit(true);
    }

}
