<span class="filter" *ngIf="data && type !== 'fav' && type !== 'importance' && type !== 'startDate'"
      [ngStyle]="{'background-color': getBackgrounColor(), 'color': getColor() }"
      [ngClass]="{'_group': type === 'group', '_statuses': type == 'statuses', '_tag': type === 'tag'}">
     <ng-container *ngIf="type == 'statuses'">
        <mat-icon class="icon _status"
                  [ngClass]="type == 'statuses' ? data.color : ''">
            {{ getActualStatusIcon }}
        </mat-icon>
    </ng-container>

    <span class="txt" [ngClass]="type == 'statuses' ? data.color : ''">
        {{ data.name }}
    </span>

    <mat-icon class="close" *ngIf="!disabled" (click)="onCloseClick()">close</mat-icon>
</span>

<span class="filter" *ngIf="data && type === 'fav'">
     <mat-icon class="icon _importance" svgIcon="radius_star-blue-filled"></mat-icon>

    <mat-icon class="close" *ngIf="!disabled" (click)="onCloseClick()">close</mat-icon>
</span>

<span class="filter" *ngIf="data && type === 'importance'">
     <mat-icon class="icon _importance" *ngIf="data.id > 0" [svgIcon]="data.icon"></mat-icon>
    <span class="txt">{{ data.title }}</span>

    <mat-icon class="close" *ngIf="!disabled" (click)="onCloseClick()">close</mat-icon>
</span>

<span class="filter" *ngIf="type == 'date'">
    <span class="txt">
        <ng-content></ng-content>
    </span>

    <mat-icon class="close" *ngIf="!disabled" (click)="onCloseClick()">close</mat-icon>
</span>

<span class="filter" *ngIf="type == 'startDate'">
    <mat-icon class="icon _importance" svgIcon="radius_calendar_start"></mat-icon>
    <mat-icon class="close" *ngIf="!disabled" (click)="onCloseClick()">close</mat-icon>
</span>
