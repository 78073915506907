import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

    /** Текущий пользователь */
    learningBoardName$ = new BehaviorSubject(null);
    openTaskLearning$ = new BehaviorSubject(false);
    allowCloseModalLearning$ = new BehaviorSubject(true);
    stepDone$ = new BehaviorSubject(null);
    viewPart$ = new BehaviorSubject(null);
    hasChanges$ = new BehaviorSubject(null);
    taskLoaded$ = new Subject();
    closeBoardsMenu$ = new Subject();
    learningSetSelected$ = new Subject();

    constructor() { }
}
