import {Injectable} from '@angular/core';
import {TasksService} from './tasks.service';
import {BoardsService} from './boards.service';
import {Router} from '@angular/router';
import {ConfigBoardsService} from './config-boards.service';
import {ConfigPhonebookService} from '../../phonebook/services/config-phonebook.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor(
      private tasks: TasksService,
      private boards: BoardsService,
      private router: Router,
      private config: ConfigBoardsService,
      private phonebook: ConfigPhonebookService

  ) { }

    openNoticeableTask(vid) {
        this.open(this.config.NOTICEABLE_TASK_URL_VID + vid);
    }

    openNoticeableTasks() {
      this.open(this.config.NOTICEABLE_URL);
    }

    closeTaskForm() {
        this.open(this.config.BOARDS_URL);
    }

    openNewTaskForm() {
        this.open(this.config.NEW_TASK_URL);
    }

    openTaskById(id) {
        this.open(this.config.TASK_URL + id);
    }

    openUsersMotivationById(id) {
      this.open(this.phonebook.MOTIVATIONS_URL  + 'approve-time-table/' + id);
    }

    openUser(id) {
      this.open(this.phonebook.USER_URL + id);
    }

    openTaskByVirtualId(vid, relativeToRoute?) {
      if (relativeToRoute) {
          this.router.navigate([`../${vid}`], {relativeTo: relativeToRoute})
      } else {
          this.open(this.config.TASK_URL_VID + vid);
      }
    }

    open(url) {
        if (this.tasks.taskHasChanges$.getValue()) {
            this.tasks.taskCloseConfirmUrl$.next(url);
            return false;
        }

        if (this.tasks.taskShortHasChanges$.getValue()) {
            this.tasks.taskShortCloseConfirmUrl$.next(url);
            return false;
        }

        if (this.boards.boardHasChanges$.getValue()) {
            this.boards.boardCloseConfirmUrl$.next(url);
            return false;
        }

        const redirectTaskUrl = this.tasks.taskCloseConfirmUrl$.getValue();
        if (redirectTaskUrl) {
            this.tasks.taskCloseConfirmUrl$.next(null);
            this.router.navigate([redirectTaskUrl]);
            return false;
        }

        const redirectTaskShortUrl = this.tasks.taskShortCloseConfirmUrl$.getValue();
        if (redirectTaskShortUrl) {
            this.tasks.taskShortCloseConfirmUrl$.next(null);
            this.router.navigate([redirectTaskShortUrl]);
            return false;
        }

        const redirectBoardUrl = this.boards.boardCloseConfirmUrl$.getValue();
        if (redirectBoardUrl) {
            this.boards.boardCloseConfirmUrl$.next(null);
            this.router.navigate([redirectBoardUrl]);
            return false;
        }
        this.router.navigate([url]);
    }

    closeBoardForm() {
        this.open(this.config.BOARDS_URL);
    }

    closeBoardCabinetForm() {
        this.open(this.config.BOARDS_CABINET_URL);
    }

    openNewBoardForm() {
        this.open(this.config.NEW_BOARD_URL);
    }

    openImportForm(source: string) {
        this.open(this.config.IMPORT_URL + '/' + source);
    }

    openBoardById(id) {
        this.open(this.config.BOARD_URL + id);
    }

    openBoardCabinetById(id) {
        this.open(this.config.BOARD_CABINET_URL + id);
    }

    closeTaskMatrix(route) {
        this.router.navigate(['../../'], {relativeTo: route, queryParamsHandling: 'merge'});
    }

    // закрытие всех окон в задачнике
    closeTaskMatrixWindow() {
        const newUrl = this.router.url.slice(0, this.router.url.indexOf('/tasks/matrix-tasks'));
        this.open(newUrl);
    }

    openMatrixById(matrixId, dateFrom?) {
      const df = moment(dateFrom).format('YYYY-MM-DD');
      const anchor = matrixId ? 'matrix' + matrixId : undefined;
        this.router.navigate([this.config.MATRIX_URL + matrixId]);
    }

    openNewMatrixById(empId, motivationId, dateFrom) {
        const df = moment(dateFrom).format('YYYY-MM-DD');
        this.router.navigate([this.config.MATRIX_URL + 'new'], { queryParams: {empId, motivationId, df}});
    }

    openMatrixByURL(matrixId) {
        this.router.navigate([this.config.MATRIX_URL + matrixId]);
    }

    openEventById(id) {
      this.router.navigate(['/apps/calendar/meet/' + id]);
    }


    openTaskInDashById(id) {
        this.open(['/apps/dashboard/tasks/'] + id);
    }

    openNewTaskInDash() {
        this.open(this.config.DASHBOARD_URL + '/task/new');
    }
}
