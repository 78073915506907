import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Tag} from '../../../../task-manager/models/tag';
import {GroupSelectComponent} from '../group-select/group-select.component';
import {Board} from '../../../../task-manager/models/board';
import {BoardsService} from '../../../../task-manager/services/boards.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Group} from '../../../../task-manager/models/group';
import {GroupsService} from '../../../../task-manager/services/groups.service';
import {FiltersService} from '../../../../task-manager/services/filters.service';
import {SharedService} from '../../../services/shared.service';
import {TasksService} from '../../../../task-manager/services/tasks.service';
import {GroupsFormComponent} from '../groups-form/groups-form.component';
import {AuthService} from '../../../../../services/auth.service';
import {SearchMatComponent} from '../../search-mat/search-mat.component';

@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit, OnDestroy, OnChanges {

    public taskBoards: Board[] = [];
    public sorted: Board[] = [];
    @Input() public boards: Board[] = [];
    @Input() public groups: Tag[] = [];
    @Input() public currentBoard: Board = null;
    public groupSelected: Tag;
    @Input() public isGroups = false;
    @Input() public isTemplate = false;
    @Input() public disabled = false;
    @Input() public isOpened = false;
    @Input() public isEditMode = true;
    @Input() public canEdit = false;
    @Input() public task;
    @ViewChild('menu') menu;
    public boardCounter = 0;

    @ViewChild('search') search: SearchMatComponent;

    public isLearning: boolean = false;

    // Триггер смерти компонента
    private destroyed = new Subject<void>();

    constructor(
        private dialog: MatDialog,
        private boardsService: BoardsService,
        private groupsService: GroupsService,
        private filtersService: FiltersService,
        private sharedService: SharedService,
        public tasksService: TasksService,
        private auth: AuthService
    ) {
    }

    get title() {
        return 'Группы';
    }

    get buttonTitle() {
        return '+ Создать группу задач';
    }

    closeMenu() {
        if (!this.isLearning) {
            this.menu.closeMenu();
        }
    }

    isBoardVisible(board) {
        if (!this.isTemplate) {
            return true;
        } else {
            return board.template_board ? this.isTemplate : !this.isTemplate;
        }
    }

    canEditGroup(board) {
        if (board.template_board) {
            return true;
        }

        const user = board.members.find(u => {
            return +u.id == +this.auth.currentUser$.value.id
        });

        if (!user) {
            return false;
        }

          return user.boardRole == 'owner' || user.boardRole == 'editor' || user.boardRole == 'member' ;
    }

    canCreateGroup(board) {
        const user = board.members.find(u => {
            return +u.id == +this.auth.currentUser$.value.id
        });

        if (!user) {
            return false;
        }

        return user.boardRole == 'owner' || user.boardRole == 'editor';
    }

    ngOnInit() {
        this.boardsService.canEditGroup$.next(this.canEdit);

        this.tasksService.openedTask$
            .pipe(takeUntil(this.destroyed))
            .subscribe(data => {
                if (data == null) {
                    this.initBoard();
                }
            })

        this.tasksService.onBoardAdd$
            .pipe(takeUntil(this.destroyed))
            .subscribe(data => {
                if (data) {
                    this.initBoard();
                }
            })

        this.tasksService.onBoardRemove$
            .pipe(takeUntil(this.destroyed))
            .subscribe(data => {
                if (data) {
                    this.initBoard();
                }
            })

        this.groupsService.onGroupUpdate$
            .pipe(takeUntil(this.destroyed))
            .subscribe(data => {
                if (this.task?.id != this.tasksService.getCurrentTaskId()) {
                    return;
                }

                if (this.groupSelected && data.id == this.groupSelected.id) {
                    this.groupSelected = data;
                    this.boards.forEach(b => {
                        let g = b.tags.find(g => g.id == data.id);

                        if (g) {
                            g.name = this.groupSelected.name;
                        }
                    });
                }
            });

        this.filtersService.selectedGroup$
            .pipe(takeUntil(this.destroyed))
            .subscribe(group => {
                if (this.task?.id != this.tasksService.getCurrentTaskId()) {
                    return;
                }

                if (group) {
                    if (this.isTemplate && this.currentBoard && this.currentBoard.id !== group['boardId']) {
                        return false;
                    }
                    this.groupSelected = group;
                    this.filtersService.selectedGroup$.next(null);
                }
            });


        this.sharedService.learningBoardName$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res && res === 'Группа') {
                    this.menu.openMenu();
                    this.isLearning = true;
                } else if (res && res === 'Добавить группу') {
                    this.openGroupAdd(this.currentBoard)
                } else {
                    if (this.menu && this.menu.menuOpen) {
                        this.menu.closeMenu();
                    }
                    this.isLearning = false;
                }
            });
    }

    boardGroup(board: Board) {
        if (board) {
            return board.tags.length ? board.tags.find(el => !!el.taskGroup) : null;
        }
        return;
    }

    isBoardCurrent(boardId) {
        return boardId === this.currentBoard.id;
    }

    getBoardColor(colorName) {
        return this.boardsService.getBoardColor(colorName);
    }

    openGroupAdd(board) {
        const data = {
            boardId: board ? board.id : null,
        };
        const dialogForm = this.dialog.open(GroupsFormComponent, {
            data: data,
            disableClose: this.sharedService.openTaskLearning$.value
        });

        dialogForm.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                if (result !== undefined) {
                    // выбрали группу в списке или Без группы

                    if (result !== null) {
                        // выбрали группу
                        result['selected'] = true;
                        if (board.id == this.currentBoard.id) {
                            this.groupSelected = result;
                        }
                        board.tags.push(result);
                        this.groupsService.onGroupSelect$.next(result);
                    }
                }
            });
    }

    expand(evt, board) {
        evt.preventDefault();
        evt.stopPropagation();
        board['collapsed'] = false
    }

    openGroupEdit(board) {
        const dialogForm = this.dialog.open(GroupSelectComponent, {
            width: '500px',
            data: {
                groups: this.groups,
                groupSelected: this.groupSelected,
                currentBoard: this.currentBoard
            }
        });

        dialogForm.afterClosed()
            .pipe(takeUntil(this.destroyed))
            .subscribe(result => {
                if (result !== undefined) {
                    // выбрали группу в списке или Без группы

                    if (result !== null) {
                        // выбрали группу
                        this.groupsService.onGroupSelect$.next(result);
                    } else {
                        // выбрали Без группы
                        const param = {
                            boardId: board.id,
                            group: this.boardGroup(board)
                        };
                        this.groupsService.onGroupRemove$.next(param);
                    }
                }
            });
    }

    getItemColorFromBoard(group: Group) {
        if (!group) {
            return;
        }
        const clone = this.groups.find(el => el.name === group.name);
        return this.groupsService.getGroupBackground(clone ? clone.color : !this.disabled ? group.color : Group.colorDisabled);
    }


    initBoard() {
        this.groups = this.currentBoard.tags.filter(el => el.taskGroup);

        if (this.isTemplate) {
            this.groupSelected = this.boardGroup(this.boards.find(el => el.template_board));
        } else {
            this.groupSelected = this.boardGroup(this.boards.find(el => el.id === this.currentBoard.id));
        }

        const globalBoards = JSON.stringify(this.boardsService.boards$.value);
        const allBoards = JSON.parse(globalBoards);

        this.taskBoards = allBoards.filter(b => this.boards.some(el => el.id == b.id));
        this.taskBoards.forEach((b) => {
            const board = this.boards.find(el => b.id == el.id);
            const tag = board.tags.find(i => i.taskGroup);

            if (tag) {
                const selected =  b.tags.find(el => +el.id == +tag.id);
                if (selected) {
                    selected['selected'] = true;
                }
            }
        });

       this.onSearchChange('');
    }

    onSearchChange(val) {
        const cashed = JSON.stringify(this.taskBoards);
        this.sorted = JSON.parse(cashed);

        if (this.isTemplate) {
            this.sorted = [this.sorted.find(b => b.template_board)]
        }

        this.sorted.forEach((b, index) => {
            const baseBoard = JSON.parse(cashed).find(el => el.id == b.id);
            const baseList = [...baseBoard.tags];
            b.tags = [...this.filterValues<any>(val, baseList)];

            if (!this.isBoardCurrent(b.id) && b.tags.length && !(b.tags.length == 1 && b.tags[0]['selected'])) {
                if (val.length) {
                    b['collapsed'] = false;
                } else {
                    b['collapsed'] = true;
                }
            }
        });

        if (val && val.length) {
            this.sorted = this.sorted.filter(b => {
                return b.tags.length
            })
        }

    }

    filterValues<Type>(searchTerm, baseList: Type[]): Type[] {
        let newList;

        if (searchTerm) {
            const terms_str = searchTerm.toLowerCase()
                .split(' ')
                .map(i => i.trim())
                .filter(i => i);
            newList = baseList.filter(
                item => terms_str.every(
                    term => this.testItem<Type>(item, term)
                )
            );
        } else {
            newList = baseList;
        }

        return newList;
    }

    testItem<Type>(item: Type, term: string) {
        return item && (this.testString(item['name'], term)) ;
    }

    testString(value: string, term: string) {
        if (!!value) {
            return value.toString().toLowerCase().includes(term);
        }
        return false;
    }

    searchClick(evt) {
        evt.stopPropagation();
    }

    onMenuClick(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    select(tag, board) {
        board.tags.forEach(tag => tag['selected'] = false);
        tag['selected'] = true;

        if (this.currentBoard.id == board.id) {
            this.groupSelected = tag;
        }

        // this.search.onClear();
        this.groupsService.onGroupSelect$.next(tag);
    }

    clear(tag, board) {
        board.tags.forEach(tag => tag['selected'] = false);

        if (this.currentBoard.id == board.id) {
            this.groupSelected = null;
        }

        const param = {
            boardId: board.id,
            group: tag
        };

        // this.search.onClear();
        this.groupsService.onGroupRemove$.next(param);
    }

    getGroupSelected(board) {
        return board.tags.find(g => g.selected);
    }

    ngOnChanges(changes:  SimpleChanges) {
        if (changes.currentBoard) {
            this.currentBoard = changes.currentBoard.currentValue;
            if (this.currentBoard) {
                this.initBoard();
            }
        }
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
