import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ApiBoardsService} from '../../../services/api-boards.service';
import {Subject} from 'rxjs';
import {AuthService} from '../../../../../services/auth.service';
import {takeUntil} from 'rxjs/operators';
import {ConfigBoardsService} from '../../../services/config-boards.service';
import {BoardsService} from '../../../services/boards.service';
import {NavService} from '../../../services/nav.service';
import {NotificationCenterService} from '../../../../notification-center/services/notification-center.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TasksTariffsDialogAdminsInfoComponent} from '../../tasks-tariffs-dialog-admins-info/tasks-tariffs-dialog-admins-info.component';
import {SharedService} from '../../../../shared/services/shared.service';

@Component({
    selector: 'app-short-form-add-board',
    templateUrl: './short-form-add-board.component.html',
    styleUrls: ['./short-form-add-board.component.scss']
})
export class ShortFormAddBoardComponent implements OnInit, OnDestroy {
// Индикатор отображения короткой формы
    public isShortFormOpened = false;
    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    // Модель формы
    public formGroupShort: UntypedFormGroup = null;
    /** Поле поиска */
    @ViewChild('taskName') nameElRef: ElementRef;
    @Output() boardCreated = new EventEmitter<boolean>();
    public MAX_BOARD_NAME_LENGTH = 50;

    // Если юзер на бесплатном тарифе
    @Input() public disabledContent = false;

    constructor(
        private notiService: NotificationCenterService,
        private api: ApiBoardsService,
        private auth: AuthService,
        private boardsService: BoardsService,
        private nav: NavService,
        private config: ConfigBoardsService,
        private dialog: MatDialog,
        private sharedService: SharedService,
    ) {
    }

    ngOnInit() {
        this.formGroupShort = new UntypedFormGroup({
            name: new UntypedFormControl('', [Validators.required, Validators.maxLength(this.MAX_BOARD_NAME_LENGTH)]),
        });
        this.sharedService.learningBoardName$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res && res === 'Новая доска') {
                    this.formGroupShort.controls['name'].setValue(res);
                }
                if (res && res === 'Save board') {
                    this.onSubmit();
                }
            });
    }

    onSubmit() {
        const str = this.formGroupShort.controls['name'].value.trim();
        this.formGroupShort.controls['name'].setValue(str);
        
        if (this.formGroupShort.invalid) {
            if (this.formGroupShort.controls['name'].errors?.maxlength) {
                this.notiService.handleError('Название должно быть не более '
                    + this.MAX_BOARD_NAME_LENGTH + ' символов ('
                    + 'текущая длина ' + this.formGroupShort.controls['name'].errors.maxlength.actualLength + ')');
            } else {
                this.notiService.handleError(this.config.FORM_INVALID);
            }
            return;
        }

        const board = {
            name: this.formGroupShort.controls['name'].value,
            members: [{user_id: this.auth.currentUser$.value.id, board_role: 'owner'}],
            color: '_grey',
        };

        const data = {board};
        this.api.createBoard(data)
            .pipe(takeUntil(this.destroyed))
            .subscribe( res => {
                this.boardsService.boardSelected$.next(res);
                this.boardCreated.emit(true);
                this.notiService.handleSuccess('Доска "' + res.name + '" создана');
                this.nav.openBoardById(res.id);
            }, err => {
                this.notiService.handleError(err.error.message);
            });
        this.closeShortForm();
    }

    openShortForm() {
        if (this.disabledContent) {
            this.showTariffsAdminInfo();
            return false;
        }
        this.isShortFormOpened = true;
        this.nameElRef.nativeElement.focus();
    }

    showTariffsAdminInfo() {
        const dialogRef = this.dialog.open(TasksTariffsDialogAdminsInfoComponent, {
            data: {title: this.config.TARIFFS_ERROR_CREATE_BOARD}
        });
    }

    onKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.onSubmit();
        }
    }

    onFocusChange() {
        if (this.isShortFormOpened && !this.formGroupShort.controls['name'].value && !this.sharedService.openTaskLearning$.value) {
            this.isShortFormOpened = false;
        }
    }

    closeShortForm() {
        this.formGroupShort.controls['name'].setValue('');
        this.isShortFormOpened = false;
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
