import {MatLegacySelect as MatSelect} from '@angular/material/legacy-select';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {statusMapping, StatusType} from '../../../../../../models/status';
import {fromEvent, Subject, Subscription} from 'rxjs';
import {MatDatepickerInput} from '@angular/material/datepicker';
import {Rights, User} from '../../../../../../models/user';
import {takeLast, takeUntil} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {File} from '../../../../../../models/file';
import * as moment from 'moment';
import {GlobalNotificationCenterService} from '../../../../../../services/global-notification-center.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {EmailValidatorValueDirective} from '../../../../../../utils/email-validator-value.directive';
import {Subdivision} from 'src/app/modules/phonebook/models/subdivision';
import {PhonebookService} from 'src/app/modules/phonebook/services/phonebook.service';
import {ApiPhonebookService} from 'src/app/modules/phonebook/services/api-phonebook.service';
import {ConfigPhonebookService} from 'src/app/modules/phonebook/services/config-phonebook.service';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {defaultDateMask, filterValues, noWhitespaceValidator} from 'src/app/utils/commons';
import {ConfirmSmartComponent} from '../../../confirm-smart/confirm-smart.component';
import {PersonalCardSaverService} from '../../../../../../services/personal-card-saver.service';
import {maskedBackspaceFix} from '../../../../../../utils/masked-backspace-fix';
import {PositionShort} from '../../../../../phonebook/models/position-short';
import {ImageCropperDialogComponent} from '../image-cropper-dialog/image-cropper-dialog.component';
import {NAME_REGEXP} from '../../../../../../utils/regexps';

@Component({
    selector: 'app-personal-card-form',
    templateUrl: './personal-card-form.component.html',
    styleUrls: ['./personal-card-form.component.scss']
})
export class PersonalCardFormComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        private config: ConfigPhonebookService,
        private phonebook: PhonebookService,
        private notiService: GlobalNotificationCenterService,
        private api: ApiPhonebookService,
        private dialog: MatDialog,
        private cdRef: ChangeDetectorRef,
        public emailValidator: EmailValidatorValueDirective,
        private saver: PersonalCardSaverService,
    ) {
    }

    get statusPeriodAvailable() {
        return this.formGroup.controls['status'].value && this.formGroup.controls['status'].value !== StatusType.BIRTHDAY;
    }
    @Input() public data: User = null;
    @Input() public rights: Rights = null;
    @Output() public readonly formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() public readonly reset: EventEmitter<any> = new EventEmitter();
    public user: User = null;
    public AVATAR_TYPE_ERROR = 'В качестве аватарки можно использовать только изображения';
    // Модель формы
    public formGroup: UntypedFormGroup = null;
    public isButtonsDisabled = false;
    public subdivisions: Subdivision[] = [];
    public subdivisionsSorted: Subdivision[] = [];
    public positions: PositionShort[] = [];
    public positionsSorted: PositionShort[] = [];
    public statuses = statusMapping.map(el => el.type);
    /** конфиг для маски дат */
    public maskConfig = defaultDateMask();
    public eventSubscription: Subscription;

    //контрол для поиска подразделений
    public subdivisionSearch: UntypedFormControl = new UntypedFormControl();

    //контрол для поиска должностей
    public positionSearch: UntypedFormControl = new UntypedFormControl();

    @ViewChild('birthdayElem') dateElem: ElementRef;
    @ViewChild('birthdayPicker') datepickerInput: MatDatepickerInput<any>;
    @ViewChild('statusRangePicker') statusRangePicker: MatDatepickerInput<any>;
    @ViewChild('autosize') autosize: CdkTextareaAutosize;
    @ViewChild('selectSubdivision') elementSubdivision: MatSelect;
    @ViewChild('selectPosition') elementPosition: MatSelect;

    private destroyed = new Subject<void>();
    private isSubmitClicked = false;
    public isLoaded = false;
    public avatar = null;
    onPhoneKeyUp = maskedBackspaceFix;

    ngAfterViewInit() {
        if (this.user.id) {
            this.eventSubscription = fromEvent(this.dateElem.nativeElement, 'input').subscribe(_ => {
               // this.datepickerInput._onInput(this.dateElem.nativeElement.value);
            });
        }
    }


    isEmptyAvatar() {
        return !this.avatar || this.avatar.search('default') >= 0 || this.avatar.search('undefined') >= 0
    }

    ngOnInit(): void {
        this.user = Object.assign({}, this.data);
        if (this.user.id) {
            this.avatar = this.config.getUserAvatarByUser(this.user, 'large');
        }

        const status = this.isStatusExpired();
        this.formGroup = new UntypedFormGroup({
            photo: new UntypedFormControl(this.user.photo),
            firstName: new UntypedFormControl(this.user.firstName, [Validators.required, noWhitespaceValidator]),
            lastName: new UntypedFormControl(this.user.lastName, [Validators.required, noWhitespaceValidator]),
            middleName: new UntypedFormControl(this.user.middleName),
            birthday: new UntypedFormControl(this.user.birthday),
            workPhone: new UntypedFormControl(this.user.workPhone),
            internalPhone: new UntypedFormControl(this.user.internalPhone),
            phones: new UntypedFormControl(this.user.phones),
            mobilePhone: new UntypedFormControl(this.user.mobilePhone),
            accounts: new UntypedFormControl(this.user.accounts),
            messenger: new UntypedFormControl(this.user.messenger),
            status: new UntypedFormControl(this.user.status ?
                this.statuses.find(el => el === this.user.status.type) : 0),
            statusStart: new UntypedFormControl(this.user.status?.dateStart && this.user.status?.dateEnd ? this.user.status.dateStart : null),
            statusEnd: new UntypedFormControl(this.user.status?.dateStart && this.user.status?.dateEnd ? this.user.status.dateEnd : null),
            location: new UntypedFormControl(this.user.location),
            additionalInfo: new UntypedFormControl(this.user.additionalInfo),
            currentPosition: new UntypedFormControl(this.user.currentPosition),
            slots: new UntypedFormControl(this.user.slots)
        });

        if (!this.user.id) {
            this.formGroup.controls['email'] = new UntypedFormControl('', [this.emailValidator.validate]);
        }

        this.isLoaded = true;


        this.subdivisionSearch.valueChanges
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => {
                this.subdivisionsSorted = filterValues<Subdivision>(this.subdivisionSearch.value, this.subdivisions);
            });

        this.positionSearch.valueChanges
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => {
                this.positionsSorted = filterValues<PositionShort>(this.positionSearch.value, this.positions, true);
            });

        this.phonebook.subdivisionCreated$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res) {
                    this.subdivisions.push(res);
                }
            });

        this.phonebook.subdivisionUpdated$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res) {
                    const index = this.subdivisions.findIndex(el => el.id === res.id);
                    if (index) {
                        this.subdivisions[index] = res;
                    }
                }
            });

        this.saver.canExit$.next(false);

        this.saver.confimExit$
            .pipe(takeUntil(this.destroyed))
            .subscribe(isVisible => {
                if (isVisible) {
                    this.onExitWithoutSaving();
                }
            });
    }

    isStatusExpired() {
        if (this.user.status) {
            const statusEnd = this.user.status.dateEnd ? moment(this.user.status.dateEnd) : null;
            if (statusEnd) {
                return statusEnd.isAfter(moment().subtract(1, 'days'));
            }
        }
        return false;
    }

    onExitWithoutSaving() {
        const dialogData = {
            title: 'Сохранить изменения?',
            buttons: [
                {
                    color: '_grey',
                    name: 'Не сохранять',
                    action: 'exit',
                    autofocus: false
                },
                {
                    color: '_blue',
                    name: 'Сохранить',
                    action: 'save',
                    autofocus: true,
                }]

        };

        const dialogRef = this.dialog.open(ConfirmSmartComponent, {
            data: dialogData
        });

        dialogRef.afterClosed()
            .pipe(takeLast(1))
            .subscribe(result => {
                if (result === 'save') {
                    this.onSubmit();
                } else if (result === 'exit') {
                    this.onReset();
                } else {
                    this.saver.confimExit$.next(false);
                }
            });
    }

    onSubmit() {
        this.isSubmitClicked = true;
        this.isButtonsDisabled = true;

        if (!this.formGroup.controls['statusStart'].value) {
            this.formGroup.controls['statusStart'].setValue(null);
        }
        if (!this.formGroup.controls['statusEnd'].value) {
            this.formGroup.controls['statusEnd'].setValue(null);
        }

        let status = {
            type: this.formGroup.controls['status'].value,
            date_start: this.formGroup.controls['statusStart'].value ?
                moment(this.formGroup.controls['statusStart'].value).utc(true) : null,
            date_end: this.formGroup.controls['statusEnd'].value ?
                moment(this.formGroup.controls['statusEnd'].value).utc(true) : null
        };

        if (this.formGroup.get('firstName').hasError('required') ||
            this.formGroup.get('lastName').hasError('required')) {
            this.notiService.handleError(this.config.FORM_INVALID);
            this.isButtonsDisabled = false;
            return;
        }

        if (this.formGroup.invalid) {
            this.notiService.handleError(this.config.FORM_INVALID_BAD);
            this.isButtonsDisabled = false;
            return;
        }

        if (!status.type) {
            status = null;
        }

        let accounts = this.formGroup.controls['accounts'].value.map(el => ({
            account: el.account,
            type: el.type,
            is_verified: el.isVerified,
            is_gmail: el.isGmail
        }));

        accounts = accounts.filter(el => el.account.length);

        const messenger = this.formGroup.controls['messenger'].value;
        if ((messenger.length === 1 && !messenger[0].type) || !messenger) {
            this.formGroup.controls['messenger'].setValue([]);
        }
        if (messenger.find(el => !el.type)) {
            const index = messenger.findIndex(el => !el.type);
            messenger.splice(index, 1);
        }

        const formData = {
            birthday: this.formGroup.controls['birthday'].value ?
                moment(this.formGroup.controls['birthday'].value).utc(true).startOf('day') : null,
            first_name: this.formGroup.controls['firstName'].value,
            internal_phone: this.formGroup.controls['internalPhone'].value,
            last_name: this.formGroup.controls['lastName'].value,
            additional_info: this.formGroup.controls['additionalInfo'].value,
            location: this.formGroup.controls['location'].value,
            work_phone: this.formGroup.controls['workPhone'].value,
            middle_name: this.formGroup.controls['middleName'].value,
            mobile_phone: this.formGroup.controls['mobilePhone'].value,
            current_position: this.formGroup.controls['currentPosition'].value,
            accounts,
            messenger: this.formGroup.controls['messenger'].value,
            phones: this.formGroup.controls['phones'].value,
            photo: this.user.photo,
            status,
            // slots: this.formGroup.controls['slots'].value,
        };

        if (!this.user.id && this.formGroup.controls['email'].value && this.formGroup.controls['email'].value.length) {
            formData['accounts'] = [
                {account: this.formGroup.controls['email'].value}
            ];
        }

        this.saver.confimExit$.next(false);
        this.formSubmit.emit(formData);
    }

    deleteAvatar($event) {
        $event.preventDefault();
        this.formGroup.controls['photo'].setValue(null);
        this.user.photo = null;
        this.avatar = null;
    }

    onPhonesChange($event) {
        const phones = $event;
        this.formGroup.controls['phones'].setValue($event);
    }

    onAccountsChange($event) {
        this.formGroup.controls['accounts'].setValue($event);
    }

    onAccountsCheck($event) {
        this.isButtonsDisabled = $event;
    }

    onMessengerChange($event) {
        this.formGroup.controls['messenger'].setValue($event);
    }

    onInternalPhoneChange($event) {
        this.formGroup.controls['internalPhone'].setValue($event);
    }

    onWorkPhoneChange($event) {
        this.formGroup.controls['workPhone'].setValue($event);
    }

    onMobileChange($event) {
        const mobile = {
            id: null,
            isVerified: false,
            phone: $event,
        };
        this.formGroup.controls['mobilePhone'].setValue(mobile);
    }

    addSubdivision($event) {
        this.reloadSlots(this.user.id);
        this.notiService.handleSuccess('Сотрудник назначен на должность');
    }

    reloadSlots(userId) {
        this.user.slots = null;
        this.isLoaded = false;

        this.api.getUserById(userId)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.user.slots = res['user'].slots;
                this.formGroup.controls['slots'].setValue(this.user.slots);
                this.isLoaded = true;
            }, (err) => {
                this.notiService.handleFullError(err);
                this.isLoaded = true;
            });
    }

    changeSubdivision($event) {
        this.reloadSlots(this.user.id);
    }

    onChangeFile($event) {
        if ($event.target.files[0].type && $event.target.files[0].type.search('image') < 0) {
            this.notiService.handleError(this.AVATAR_TYPE_ERROR);
            return false;
        }

        const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
            data: $event
        });

        dialogRef.afterClosed()
            .pipe(takeLast(1))
            .subscribe(result => {
                if (result) {

                    this.avatar = null;
                    const uploadData = new FormData();

                    uploadData.append('upload', result);
                    uploadData.append('entity_id', 'user');
                    uploadData.append('user_id', `${this.user.id}`);

                    this.api.uploadFile(uploadData)
                        .pipe(takeUntil(this.destroyed))
                        .subscribe(res => {
                            const file = JSON.deserialize<File>(res, File);
                            this.formGroup.controls['photo'].setValue(file.photo ? file.photo : file.name);
                            this.user.photo = file.photo ? file.photo : file.name;
                            this.avatar = this.config.getUserAvatarByUser(this.user, 'large');
                            this.cdRef.detectChanges();
                        }, err => {
                            this.notiService.handleFullError(err);
                        });
                }
            });
    }

    statusName(statusType) {
        const status = statusMapping.find(el => el.type === statusType);
        return status ? status.name : '';
    }

    hasError(elem) {
        return (this.isSubmitClicked && elem.invalid) || (elem.invalid && (elem.dirty || elem.touched));
    }

    onReset() {
        this.saver.canExit$.next(true);
        this.saver.confimExit$.next(false);
        this.reset.emit(true);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }


    isEmailValid(phone: string | undefined) {
        if (!phone) {
            return true;
        }

        return phone.length === 0 || phone.length >= 11;
    }

    protected readonly NAME_REGEXP = NAME_REGEXP;
}
