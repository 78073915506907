import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {fromEvent, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements AfterViewInit, OnDestroy {
    @Input() public inputValue = '';

    @Input() placeholder = 'Поиск';
    @Input() closeVisible = false;
    /** Поле поиска */
    @ViewChild('searchTerm', { static: true }) searchTermElRef: ElementRef;

    @Output() searchTerm = new EventEmitter<any>();
    @Output() blur = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();
    @Input() size;
    @Input() outline;
    @Input() autoFocus = 'autofocus';

    /** Подписки живут пока не... */
    destroyed: Subject<void> = new Subject<void>();
    public str = '';
    constructor() {
    }

    focus() {
        this.searchTermElRef.nativeElement.focus();
    }

    ngAfterViewInit() {
        fromEvent(this.searchTermElRef.nativeElement, 'keyup')
            .pipe(
                takeUntil(this.destroyed),
                debounceTime(500),
                distinctUntilChanged())
            .subscribe((keyboardEvent: any) => {
                if (this.str !== keyboardEvent.target.value) {
                    this.str = keyboardEvent.target.value;
                    this.searchTerm.emit(keyboardEvent.target.value);
                }
            });
    }

    /**
     * очистить поле, сбросить поиск
     */
    clear() {
        this.inputValue = '';
        this.searchTerm.emit('');
        this.close.emit(true);
    }

    ngOnDestroy() {
        this.clear();
        this.destroyed.next();
        this.destroyed.complete();
    }

}
