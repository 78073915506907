import { NotifierService } from 'angular-notifier';
import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { NotificationCenterItem } from '../models/notificationCenter';

@Injectable({
    providedIn: 'root',
})
export class GlobalNotificationCenterService {
    // Информация о том, что необходимо обновить (добавить) уведомление
    public updateNotificationItem$ = new Subject<NotificationCenterItem>();
    // Получение счетчика новых уведомлений для шапки (число непрочитанных уведомлений)
    notificationsHeaderMessageCount$ = new BehaviorSubject(0);
    // Получения ids прочитанных сообщений
    notificationsReadEvents$ = new Subject<string[]>();
    // Каунтер на иконку обучения
    learningCount$ = new Subject<number>();
    // Каунтер на иконку мотиваций
    motivationCount$ = new BehaviorSubject<number>(0);
    // Каунтер на иконку мотиваций в ЛК
    motivationCabinetCount$ = new BehaviorSubject<number>(0);
    // Каунтер на иконку матриц
    matrixCount$ = new BehaviorSubject<number>(0);
    // Каунтер на иконку багтрекера
    tasksCount$ = new Subject<number>();
    // Каунтер на иконку вопросов пользователя
    usersQuestionsCount$ = new Subject<number>();
    // Каунтер на иконку планировщика
    boardsCount$ = new BehaviorSubject<number>(0);
    // Каунтер на иконку финансов
    financeCount$ = new Subject<number>();
    // Каунтер на иконку опросов
    surveyCount$ = new Subject<number>();
    matrixCabinetCount$ = new BehaviorSubject<number>(0);

    constructor(private notifierService: NotifierService) {}

    /**
     * обработчик ошибок
     *
     * @param err String
     */
    public handleFullError(err) {
        this.handleError(
            err && err.error && err.error.message
                ? err.error.message
                : err.message,
        );
    }

    public handleError(errMsg) {
        if (errMsg) {
            this.notifierService.notify('error', errMsg);
        } else {
            this.notifierService.notify(
                'error',
                'Ошибка соединения сети интернет',
            );
        }
    }

    public handleSuccess(msg) {
        if (msg) {
            this.notifierService.notify('success', msg);
        }
    }

    public handleMessage(msg) {
        if (msg) {
            this.notifierService.notify('info', msg);
        }
    }
}
