import {JsonObject, JsonProperty, JsonType} from 'ta-json';

@JsonObject()
export class SettingsPhonebook {
    @JsonProperty('view')
    @JsonType(String)
    view: string;

    @JsonProperty('sort')
    @JsonType(String)
    sort: string;

    @JsonProperty('subdivisionsFilter')
    @JsonType(String)
    subdivisionsFilter: any;

    @JsonProperty('noMailFilter')
    @JsonType(Boolean)
    noMailFilter: boolean;

    @JsonProperty('notConfirmMailFilter')
    @JsonType(Boolean)
    notConfirmMailFilter: boolean;

    @JsonProperty('subdivisionsActiveFilter')
    @JsonType(String)
    subdivisionsActiveFilter: any;

    constructor(
        view: string = 'LIST',
        sort: string = 'ABC',
        noMailFilter = false,
        notConfirmMailFilter = false,
        subdivisionsFilter = [],
        subdivisionsActiveFilter = []
    ) {
        this.view = view;
        this.sort = sort;
        this.noMailFilter = noMailFilter;
        this.notConfirmMailFilter = notConfirmMailFilter;
        this.subdivisionsFilter = subdivisionsFilter;
        this.subdivisionsActiveFilter = subdivisionsActiveFilter;
    }
}
