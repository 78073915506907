import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Board} from '../../../../task-manager/models/board';
import {BoardsService} from '../../../../task-manager/services/boards.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TasksService} from '../../../../task-manager/services/tasks.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {NavService} from '../../../../task-manager/services/nav.service';
import {AuthService} from '../../../../../services/auth.service';
import {NotificationCenterService} from '../../../../notification-center/services/notification-center.service';
import {ApiBoardsService} from '../../../../task-manager/services/api-boards.service';

@Component({
    selector: 'app-boards-add',
    templateUrl: './boards-add.component.html',
    styleUrls: ['./boards-add.component.scss']
})
export class BoardsAddComponent implements OnInit, OnDestroy {

    public boards: Board[] = [];
    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    selected = [];
    canCreate = true;
    showNull = false;
    nullBoard = new Board();

    constructor(
        private boardsService: BoardsService,
        private tasksService: TasksService,
        private notiService: NotificationCenterService,
        private api: ApiBoardsService,
        private nav: NavService,
        private auth: AuthService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: Board[] = [],
    ) {
    }

    ngOnInit() {
        this.boards = this.boardsService.boards$.getValue();
        this.boardsService.boards$
            .pipe(takeUntil(this.destroyed))
            .subscribe(boards => {
                if (boards) {
                    this.boards = boards;
                    this.boards = this.boards.filter(b => b.id > 0 && !b.template_board && !this.itemSelected(b));
                }
            });

        if (this.data['selected']) {
            this.selected = this.data['selected']
        }
        if (this.data['canCreate']) {
            this.canCreate = this.data['canCreate']
        }

        if (this.data['showNull']) {
            this.nullBoard.name = this.data['nullTitle'] ? this.data['nullTitle'] : 'Не выбрано';
            this.showNull = true;
        }
    }

    itemSelected(item) {
        return this.selected.some(el => el.id === item.id);
    }

    onBoardSelect(item: Board) {
        const board = {...item};
        board.tags = [];
        this.close(board);
    }

    close(result?) {
        this.dialogRef.close(result);
    }

    getItemColor(colorName) {
        return this.boardsService.getBoardColor(colorName);
    }

    getBoardName(board) {
        return this.boardsService.getBoardName(board, this.auth.currentUser$.getValue());
    }

    createBoard() {
        const board = {
            name: 'Первая доска компании',
            members: [{user_id: this.auth.currentUser$.value.id, board_role: 'owner'}],
            color: '_grey',
        };

        const data = {board};
        this.api.createBoard(data)
            .pipe(takeUntil(this.destroyed))
            .subscribe( res => {
                this.notiService.handleSuccess('Доска "' + res.name + '" создана');

                const board = {...res};
                board.tags = [];

                // добавляем в список досок
                const boards = [...this.boardsService.boards$.getValue(), board];
                this.boardsService.boards$.next(boards);

                // выбираем сразу же
                this.close(board);
            }, err => {
                this.notiService.handleError(err.error.message);
            });

    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
