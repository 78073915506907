import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';

@Component({
    selector: 'app-assessment',
    templateUrl: './assessment.component.html',
    styleUrls: ['./assessment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentComponent{

    public value = null;

    constructor(
        private cdr: ChangeDetectorRef
    ) {
    }

    @Input() set data(param: any) {
        this.value = param;
        this.cdr.detectChanges();
    }
}
