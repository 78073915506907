import {Board} from '../../task-manager/models/board';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Matrix, MatrixSetting, Motivation, UserMatrix} from '../models/matrix/matrix';
import {UsersMotivation} from '../models/motivations/users-motivation';
import * as moment from 'moment';
import {Filter} from '../../task-manager/models/filter';

@Injectable({
    providedIn: 'root'
})
export class MatrixService {
    public settings = new BehaviorSubject<MatrixSetting>(null);

    public openedMatrix$ = new BehaviorSubject<Matrix>(null);
    public cardOpened$ = new BehaviorSubject(null);
    public chatOpened$ = new BehaviorSubject(null);
    public matrixSidebar$ = new BehaviorSubject(null);
    public matrixSearchResult$ = new BehaviorSubject<Matrix[]>(null);
    public isExecutive$ = new BehaviorSubject(false);

    public expandedList$ = new BehaviorSubject<number[]>([]);

    public scrolled$ = new BehaviorSubject<boolean>(false);
    public updateCardMatrix$ = new BehaviorSubject(null);
    public updateMatrixInTable$ = new BehaviorSubject<number>(null);

    public motivationCabinet$ = new BehaviorSubject<UsersMotivation>(null);
    public motivationCabinetOpened$ = new BehaviorSubject<boolean>(false);
    public currentEmpType$ = new BehaviorSubject<Motivation>(null);

    public selectedMatrixDetails$ = new BehaviorSubject<Matrix>(null);
    public selectedMatrix$ = new BehaviorSubject<Number>(null);
    public employeeTypes$ = new BehaviorSubject(null);

    public openedDate$ = new BehaviorSubject<Date>(null);
    public selectedUserMatrix$ = new BehaviorSubject<UserMatrix>(null);
    public myBoardForCreatingTask$ = new BehaviorSubject<Board>(null);
    public updateTasks$ = new BehaviorSubject<boolean>(null);

    // проверка что открыто окно добавления задачи
    public addTaskWindowOpen$ = new BehaviorSubject<boolean>(null);
    // проверка что открыто окно задачника
    public viewTaskWindowOpen$ = new BehaviorSubject<boolean>(null);
    public isAdmin$ = new BehaviorSubject<boolean>(false);
    selectedTaskId$ = new BehaviorSubject<number>(null);
    selectedMetricId$ = new BehaviorSubject<number>(null);
    createdMatrixEvent$ = new BehaviorSubject<any>(null);
    forceUpdateSubdivisionTable$ = new BehaviorSubject<any>(null);
    insertMatrixInTable$ = new BehaviorSubject<any>(null);
    recalc$ = new BehaviorSubject(null);
    matricesSubj$ = new BehaviorSubject(null);
    changeWeight$= new BehaviorSubject(null);
    changeWeightKPI$= new BehaviorSubject(null);
    changeActive$= new BehaviorSubject(null);
    pushKpiTemplate$= new BehaviorSubject(null);
    count$ = new BehaviorSubject<number>(0);
    openedMatrixId$ = new BehaviorSubject<number>(0);
    openedMatrixLoaded$= new BehaviorSubject<boolean>(false);
    deleteKpiTemplate$= new BehaviorSubject<number>(0);

    visibleColumns = new BehaviorSubject<string[]>([]);
    //     'period',
    //     'smart-task-percent-done',
    //     'metric-percent-done',
    //     'sum-plan',
    //     'percent-fact',
    //     'agreement'
    // ];
    defaultCols = [
        'period',
        'smart-task-percent-done',
        'metric-percent-done',
        'sum-plan',
        'percent-fact',
        'agreement'
    ];
    openSettings$= new BehaviorSubject<boolean>(false);
    showEmpty$ = new BehaviorSubject<boolean>(false);
    filters$ = new BehaviorSubject<Filter>(null);
    mode$ = new BehaviorSubject<string>('subdivisions');
    isEditMode$ = new BehaviorSubject<boolean>(false);
    expandHierarchy$ = new BehaviorSubject<boolean>(false);



    changeDateFormat() {
        return  moment(this.openedDate$.value).startOf('month').format('YYYY-MM-DD');
        //
        // if (this.openedDateCard$.value){
        //     return this.openedDateCard$.value;
        // } else {
        //     return this.openedDate$.value.toISOString().substring(0, 10);
        // }
    }
    
}
