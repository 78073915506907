import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {MotivationsSettings} from '../models/motivations/motivations-settings';
import {Rights} from '../../../models/user';
import {MotivationsCustomField} from '../models/motivations/motivations-custom-field';
import {Application} from '../models/application';
import {Motivation} from '../models/motivations/motivation';

@Injectable({
    providedIn: 'root'
})
export class MotivationsService {

    public motivationsApps$ = new BehaviorSubject<Application[]>(null);
    public activePage$ = new BehaviorSubject<Application>(null);
    public openSettings$ = new Subject();
    public settings$ = new BehaviorSubject<MotivationsSettings>(null);
    public rights$ = new BehaviorSubject<Rights>(null);
    public editSettings$ = new Subject<MotivationsSettings>();

    public settingsReload$ = new Subject();
    public createCustomField$ = new Subject();
    public editCustomField$ = new Subject();
    public deleteCustomField$ = new Subject();

    public customFieldCreated$ = new Subject<MotivationsCustomField>();
    public customFieldUpdated$ = new Subject<MotivationsCustomField>();
    public customFieldRemoved$ = new Subject<number>();

    public motivationCreated$ = new Subject();
    public motivationRemoved$ = new Subject();
    public motivationUpdated$ = new Subject();

    public employmentCreated$ = new Subject();
    public usersMotivationRemoved$ = new Subject();
    public employmentUpdated$ = new Subject();

    public searchTerm$ = new Subject<string>();
    public isExecutive$ = new BehaviorSubject(false);
    public isAdmin$ = new BehaviorSubject(false);

    public motivationApproveChange$ = new Subject<Motivation>();
    public hiddenSubdivisionId$ = new BehaviorSubject(null);

    public openMotivationById$ = new BehaviorSubject(null);

    public defaultFields = [
        {value: 'employmentType', title: 'Трудоустройство'},
        {value: 'dateFinish', title: 'Действует до'},
        {value: 'periodicity', title: 'Периодичность начисления'},
        {value: 'accrualType', title: 'Форма оплаты'},
        {value: 'salary', title: 'Оклад'},
        {value: 'variablePath', title: 'Переменная часть'},
    ];
    employmentDeleted$ = new BehaviorSubject(null);
    closeSearch$ = new BehaviorSubject(null);
    trySave = new BehaviorSubject(null);

    constructor() {
    }
}
