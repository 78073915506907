import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import plural from 'plural-ru';
import {FormControl} from '@angular/forms';
import {JsonProperty, JsonType} from 'ta-json';
import {ApiMotivationsService} from '../../../../../../../services/api-motivations.service';
import {MatrixService} from '../../../../../../../services/matrix.service';
import {GlobalNotificationCenterService} from '../../../../../../../../../services/global-notification-center.service';
import {Matrix} from '../../../../../../../models/matrix/matrix';



// TODO: MOVE TO MODELS!!
export interface MetricSource {
    id: number;
    name: string;
    rating: number;
    title: string;
    description?: string;
}

export class KPITemplate {
    @JsonProperty('id')
    @JsonType(Number)
    id: number;

    @JsonProperty('description')
    @JsonType(String)
    description: string;

    @JsonProperty('position_id')
    @JsonType(Number)
    positionId: number;

    @JsonProperty('range_unit')
    @JsonType(String)
    rangeUnit: string;

    @JsonProperty('ranges')
    @JsonType(String)
    ranges: any[];

    @JsonProperty('title')
    @JsonType(String)
    title: string;
}

export const NEW_KPI_CARD = -1;

@Component({
    selector: 'app-add-metrics',
    templateUrl: './add-metrics.component.html',
    styleUrls: ['./add-metrics.component.scss']
})
export class AddMetricsComponent implements OnInit, OnDestroy {
    metrics: MetricSource[];
    destroyed = new Subject<any>();
    @Input() matrixInfo: Matrix;
    metricSubmit = [];
    @Output() close = new EventEmitter<null>();
    @Output() save = new EventEmitter<any>();

    @Output() openKPICard = new EventEmitter<any>();
    isLoaded = false;

    searchQuery = new FormControl('');
    kpiTemplatesList: KPITemplate[] = [];
    kpiSubmit = [];
    selectedTemplates: KPITemplate[] = [];
    searchStr: string = '';

    constructor(
        public api: ApiMotivationsService,
        public matrix: MatrixService,
        public notiService: GlobalNotificationCenterService,
    ) {
    }

    ngOnInit(): void {
        this.getMetrics();

        // initial search
        this.onSearchChange('');

        // search on query change
        this.searchQuery.valueChanges
            .pipe(debounceTime(500))
            .pipe(takeUntil(this.destroyed))
            .subscribe(query => {
                this.onSearchChange(query);
            });

        this.matrix.pushKpiTemplate$
            .pipe(takeUntil(this.destroyed))
            .subscribe(kpiTemp => {
                this.onSearchChange(this.searchQuery.value);
            });

        this.matrix.deleteKpiTemplate$.pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                if (resp) {
                    this.deleteTemplate(resp);
                }
            });
    }

    getMetrics() {
        this.isLoaded = false;
        this.api.getAllMetrics(this.matrixInfo.user.id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(resp => {
                this.metrics = resp;
                this.isLoaded = true;
            });
    }

    ngOnDestroy(): void {
        this.destroyed.next(true);
    }

    onAddMetricClick($event: MouseEvent, m: MetricSource) {
        $event.preventDefault();

        if (!this.notAdded(m)) {
            return;
        }

        if (this.metricSubmit.includes(m.id)) {
            this.metricSubmit = this.metricSubmit.filter(mid => mid !== m.id);
        } else {
            this.metricSubmit.push(m.id);
        }
    }

    onClose() {
        this.close.emit();
    }

    onSave() {
        this.save.emit({metric: this.metricSubmit, kpi: this.kpiSubmit});
    }


    pluralize(n: number, w1: string, w2: string, w5: string) {
        return plural(n, w1, w2, w5);
    }

    notAdded(met: MetricSource) {
        return !this.matrixInfo.matrixMetrics.find(m => m.metric?.id === met.id) &&
            !this.matrixInfo.matrixMetricsAddFixed.find(m => m.metric?.id === met.id) &&
            !this.matrixInfo.matrixMetricsAddPercent.find(m => m.metric?.id === met.id);
    }

    addNewKPITemplate() {
        this.openKPICard.emit(NEW_KPI_CARD);
    }

    onSearchChange(query: string) {
        this.searchStr = query;
        this.api.getKPIList(query)
            .pipe(takeUntil(this.destroyed))
            .subscribe(response => {
                this.kpiTemplatesList = response;
            });
    }

    onAddKPIClick($event: MouseEvent, m: KPITemplate) {
        $event.preventDefault();

        if (!this.notAddedKPI(m)) {
            return;
        }

        if (this.kpiSubmit.includes(m.id)) {
           this.onDeselectKPITemplate(m);
        } else {
            this.kpiSubmit.push(m.id);
            this.selectedTemplates.push(m);
        }
    }

    notAddedKPI(met: KPITemplate) {
        return         !this.matrixInfo.matrixMetrics.find(m => m.template?.id === met.id) &&
            !this.matrixInfo.matrixMetricsAddFixed.find(m => m.template?.id === met.id) &&
            !this.matrixInfo.matrixMetricsAddPercent.find(m => m.template?.id === met.id);
    }

    clearSelectedTemplated($event: MouseEvent) {
        $event.preventDefault();

        this.kpiSubmit = [];
        this.selectedTemplates = [];
    }

    onDeselectKPITemplate(t: KPITemplate) {
        this.kpiSubmit = this.kpiSubmit.filter(mid => mid !== t.id);
        this.selectedTemplates = this.selectedTemplates.filter(s => s.id !== t.id);
    }

    clear() {
        this.searchQuery.setValue('');
    }

    deleteTemplate(id: number) {
        this.selectedTemplates = this.selectedTemplates.filter(t => t.id !== id);
        this.kpiSubmit = this.kpiSubmit.filter(t => t !== id);
        this.kpiTemplatesList = this.kpiTemplatesList.filter(t => t.id !== id);
    }

    openKPITemplateCard($event: MouseEvent, m: KPITemplate) {
        $event.preventDefault();
        $event.stopPropagation();

        this.openKPICard.emit(m);
    }
}
