import * as moment from 'moment';
import {ConnectionService} from 'ng-connection-service';
import {CookieService} from 'ngx-cookie-service';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {JSON} from 'ta-json';

import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {debounceTime, takeUntil} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {ChatRoomsItem} from '../../models/ChatRoomsItem';
import {ChatMessage} from '../../models/chat';
import {NotificationCenterItem} from '../../models/notificationCenter';
// import * as IntroJs from 'IntroJs';
// import { CommonService } from '../../shared-module/services/common.service';
// declare var introJs: any;
import {ApiCabinetService} from '../../modules/cabinet-company/services/api-cabinet.service';
import {CalendarService} from '../../modules/calendar/services/calendar.service';
import {CompanyService} from '../../modules/phonebook/services/company.service';
import {MatrixService} from '../../modules/phonebook/services/matrix.service';
import {QuestionsService} from '../../modules/questions/services/questions.service';
import {BoardsService} from '../../modules/task-manager/services/boards.service';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {ChatsService} from '../../services/chats.service';
import {GlobalNotificationCenterService} from '../../services/global-notification-center.service';
import {MessagingService} from '../../services/messaging.service';
import {OnboardingService} from '../../services/onboarding.service';
import {StorageService} from '../../services/storage.service';
import {WebSocketConfig, WebsocketService,} from '../../services/webSocket.api';
import {ApiPhonebookService} from '../../modules/phonebook/services/api-phonebook.service';

/** Конфигурация веб сокета для чатов */
export const chatConfig: WebSocketConfig = {
    url: environment.wsChatApiPath,
    reconnectInterval: 1000,
    reconnectAttempts: 5000,
};

const wsReconnectInterval = 1000;

export enum MainSidebarTypes {
    CHATS = 'CHATS',
    NOTIFICATIONS = 'NOTIFICATIONS',
    QUESTIONS = 'QUESTIONS',
}

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    providers: [WebsocketService, { provide: 'config', useValue: chatConfig }],
})
export class MainComponent implements OnInit, OnDestroy {
    readonly SidebarType = MainSidebarTypes;
    public activeSidebar = null;
    public message: any;
    public isAbpDetect = false;
    public isConnected = false;
    public ignoringDisconnect = false;
    public connectionTimeOut = null;
    // private DEAD_TIME_MOUSE_MOVE = 5000; // 5sec
    public status = 'ONLINE';
    public isOnline = true;
    private destroyed = new Subject<void>();
    // Триггер разрыва сокетов
    private wsClosed = new Subject<void>();
    private wsSubscriptions: Subscription[] = [];
    isFreePanelVisible = false;
    showMessage = false;
    showMessageChanged = false;

    promoDate = moment.utc('2023-10-31');
    pricingPlanChangeDate = moment.utc('2023-11-01');
    pricingPlanDate = moment.utc('2023-10-31');

    now = moment().utc(true);

    isAfter30October2023 = this.now.isAfter(moment.utc('2023-10-30'), 'd');
    public activityTimer = null;
    public versionIsDeprecated = false;
    public actualVersion = null;
    public localVersion = null;
    public ignoreVersionDeprecated = false;
    public firstOpening = true;

    // маркер если задачник протух на час
    private taskManagerToOld = false;

    @HostListener('mousemove', ['$event']) onMouseMove(event) {
        this.setActivityTimer();
    }

    constructor(
        public auth: AuthService,
        public api: ApiService,
        private chatsService: ChatsService,
        private messagingService: MessagingService,
        private matrixService: MatrixService,
        private wsService: WebsocketService,
        private router: Router,
        private notificationCenterService: GlobalNotificationCenterService,
        private boardsService: BoardsService,
        private questionsService: QuestionsService,
        private connectionService: ConnectionService,
        private route: ActivatedRoute,
        private cookies: CookieService,
        private storage: StorageService,
        private onboarding: OnboardingService,
        private company: CompanyService,
        private calServ: CalendarService,
        private tariffApi: ApiCabinetService,
        private apiPhoneBookService: ApiPhonebookService
    ) {
        this.connectionService
            .monitor()
            .pipe(takeUntil(this.destroyed))
            .subscribe((isConnected) => {
                console.log(isConnected);
                this.isOnline = isConnected.hasNetworkConnection;
                if (this.isOnline) {
                    this.status = 'ONLINE';
                    this.wsService.onLine$.next(true);
                } else {
                    this.status = 'OFFLINE';
                    this.wsService.onLine$.next(false);
                }
            });
    }

    clearActivityTimer() {
        this.activityTimer = null;
        localStorage.setItem(
            'activityCounterTimer',
            new Date().getTime().toString(),
        );

        if (this.taskManagerToOld) {
            this.taskManagerToOld = false;
            this.boardsService.resetBoard$.next(true);
        }
    }

    checkActivityTimer() {
        const saved = +localStorage.getItem('activityCounterTimer');
        const now = new Date().getTime();

        if (now - saved > 172800000) {
            this.activityTimer = true;
        }

        if (now - saved > 3600000) {
            this.taskManagerToOld = true;
        }
    }

    setActivityTimer() {
        if (!this.activityTimer) {
            this.clearActivityTimer();
        }
    }

    get isPromoPricingPlan() {
        return this.pricingPlanDate.diff(this.promoDate, 'days') <= 0;
    }

    readonly TARIFF_MESSAGE_THRESHOLD = 5;

    ignoreDisconnect() {
        this.ignoringDisconnect = true;
        setTimeout(() => {
            this.ignoringDisconnect = false;
        }, 60000);
    }

    ignoreVersion() {
        this.localVersion = this.actualVersion;
        this.ignoreVersionDeprecated = true;

        setTimeout(() => {
            this.localVersion = null;
            this.ignoreVersionDeprecated = false;
        }, 3600000);
    }

    refresh() {
        this.clearActivityTimer();
        window.location.reload();
    }

    checkShowMessage() {
        // принудительно показываем первый раз
        const promoEndSeen = localStorage.getItem('promoEndSeen');

        // последний раз закрывали
        const tariffChangeSeen = localStorage.getItem('tariffChangeSeen');
        const momentTariffChangeSeen = moment(tariffChangeSeen).isValid()
            ? moment(tariffChangeSeen)
            : moment('1969-01-01');

        // console.log(momentTariffChangeSeen.toISOString(),
        //     this.pricingPlanDate.toISOString(),
        //     this.now.toISOString(),
        //     this.pricingPlanDate.diff(this.now, 'day'),
        //     this.pricingPlanDate.diff(momentTariffChangeSeen, 'day'));

        if (
            !promoEndSeen || // скоро кончится
            (this.pricingPlanDate.diff(this.now, 'day') <=
                this.TARIFF_MESSAGE_THRESHOLD &&
                this.pricingPlanDate.diff(momentTariffChangeSeen, 'day') >
                    this.TARIFF_MESSAGE_THRESHOLD)
        ) {
            this.showMessage = true;
        }

        // console.log(momentTariffChangeSeen.toISOString(),
        //     this.pricingPlanChangeDate.toISOString(),
        //     this.now.toISOString(),
        //     this.pricingPlanChangeDate.diff(this.now, 'day'),
        //     this.pricingPlanChangeDate.diff(momentTariffChangeSeen, 'day'));

        if (
            // сменился
            this.pricingPlanChangeDate.diff(this.now, 'day') <= 0 &&
            this.pricingPlanChangeDate.diff(momentTariffChangeSeen, 'day') > 0
        ) {
            this.showMessageChanged = true;
        }
    }

    ngOnInit() {
        this.clearActivityTimer();
        setInterval(() => {
            this.checkActivityTimer();
        }, 1000);
        this.chatsService.getChats$
            .pipe(takeUntil(this.destroyed), debounceTime(500))
            .subscribe((res) => {
                this.preloadChats();
            });
        // this.storage.users$
        //     .pipe(takeUntil(this.destroyed))
        //     .subscribe(res => {
        //         if (res) {
        //             return false;
        //         }
        //         this.getUsers();
        //     });

        this.storage.updateUsers$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                this.getUsers();
            });

        this.getUsers();

        this.isFreePanelVisible = this.cookies.get('free-panel-hide') === '';

        this.route.queryParams
            .pipe(takeUntil(this.destroyed))
            .subscribe((params) => {
                if (
                    params.chat_user_id &&
                    params.first_name &&
                    params.last_name
                ) {
                    const data = {
                        id: params.chat_user_id,
                        name: params.first_name + ' ' + params.last_name,
                    };

                    this.chatsService.openChat$.next(data);
                }
            });

        this.chatsService.openChat$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                if (res) {
                    this.activeSidebar = this.SidebarType.CHATS;
                }
            });

        this.api.onCloseSidebar$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                this.activeSidebar = null;
            });

        this.api.appList$.pipe(takeUntil(this.destroyed)).subscribe((list) => {
            if (list.length && this.router.url === '/' && list[0].url) {
                this.router.navigate(['/apps/dashboard']);
            }
        });

        this.auth
            .fetchUserInfo()
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                this.getCompanySettings();
            });

        this.router.events.pipe(takeUntil(this.destroyed)).subscribe((res) => {
            if (res && res['type'] && res['type'] == 15) {
                return false;
            }

            this.activeSidebar = null;
        });

        this.api
            .getHints()
            .pipe(takeUntil(this.destroyed))
            .subscribe((resp) => {
                if (resp && Object.keys(resp).length) {
                    this.onboarding.viewedHints$.next(resp);
                }
                this.onboarding.loadHints$.next(true);
            });

        this.onboarding.viewedHints$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                if (
                    res &&
                    Object.keys(res).length &&
                    this.onboarding.loadHints$.getValue()
                ) {
                    this.api
                        .updateHints(res)
                        .pipe(takeUntil(this.destroyed))
                        .subscribe((resp) => {});
                }
            });

        this.wsService.connected$
            .pipe(takeUntil(this.destroyed))
            .subscribe((connected) => {
                console.log('wsConnection: ' + connected);
                if (connected) {
                    this.subscribeWsChanges();
                    this.isConnected = true;
                } else {
                    this.wsSubscriptions.forEach((s) => s.unsubscribe());
                    this.wsSubscriptions.splice(0, this.wsSubscriptions.length);
                    this.wsClosed.next();
                    this.isConnected = false;
                }
            });

        this.initPushMessaging();

        setInterval(() => {
            this.wsService.manualCheckConnectionWs();
        }, wsReconnectInterval);

        this.checkAds();

        this.isConnected = true;

        this.getBoardsCounters();

        this.wsService.pingPong$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                if (res) {
                    setTimeout(() => {
                        if (this.wsService.pingPong$.value) {
                            console.log('ping pong timeout');
                            this.wsService.manualReconnect();
                        }
                    }, 3500);
                }
            });

        this.auth.updateTimezone$
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                if (res) {
                    this.updateTimeZone(res);
                }
            })
    }

    updateTimeZone(data) {
        this.apiPhoneBookService.editUser(data, this.auth.auth.id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                console.log(res)
            }, (err) => {
               this.notificationCenterService.handleError(err);
            });
    }

    getCompanySettings() {
        const settingsObs = this.api.getCompanySettings();
        const tariffsObs = this.api.getCompanyTariffs(['boards', 'calendar']);

        let retry = 5;

        forkJoin({
            settings: settingsObs,
            tariffs: tariffsObs,
        })
            .pipe(takeUntil(this.destroyed))
            .subscribe(({ settings, tariffs }) => {
                this.company.$settings.next(settings);

                this.auth.setTariffsUsers(tariffs);

                if (tariffs['boards']) {
                    this.auth.setBoardsTariffs(tariffs['boards']);
                    // if (!this.auth.getSelfPaid()) {
                    //     this.boardsService.selectDefaultBoard(this.auth.auth.id);
                    // }
                } else {
                    if (retry > 0) {
                        retry -= 1;
                        setTimeout(() => {
                            this.getCompanySettings();
                        }, 1000);
                    } else {
                        this.notificationCenterService.handleError(
                            'Произошла ошибка',
                        );
                    }
                }
            });
    }

    getBoardsCounters() {
        /** Получение каунтеров новых задач при загрузке приложения */
        this.api
            .getBoardsCounters()
            .pipe(takeUntil(this.destroyed))
            .subscribe((counters) => {
                this.notificationCenterService.boardsCount$.next(
                    counters['count'],
                );
                if (counters['count_per_board']) {
                    this.boardsService.countsPerBoard$.next(
                        counters['count_per_board'],
                    );
                }
            });
    }

    getQuestionsCounters() {
        /** Получение каунтеров новых вопросов при загрузке приложения */
        this.api
            .getUsersQuestionsCounters()
            .pipe(takeUntil(this.destroyed))
            .subscribe((counters) => {
                this.notificationCenterService.usersQuestionsCount$.next(
                    counters['count'],
                );
                if (counters['count_per_questions']) {
                    console.log(counters);
                    this.boardsService.countsPerBoard$.next(
                        counters['count_per_questions'],
                    );
                }
            });
    }

    checkAds() {
        setTimeout(() => {
            const adv = document.getElementById('ZyEHFVgJnWcr');
            if (!adv || !adv.offsetHeight) {
                this.isAbpDetect = true;
            }
        }, 1000);
    }

    /*
     * Инит сервиса пуш-уведомлений
     */
    initPushMessaging() {
        const userId = 'user' + this.auth.auth.id;
        this.messagingService.requestPermission(userId);
        this.messagingService.receiveMessage();
        this.message = this.messagingService.currentMessage;
    }

    /*
     * Следим за изменениями в соединении чатов
     */
    subscribeWsChanges() {
        if (!this.wsService.websocket$) {
            return false;
        }
        this.wsSubscriptions.push(
            this.wsService.websocket$
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res) => {
                    const params = JSON.deserialize<any>(res);
                    if (params.event === 'header_message_count') {
                        this.chatsService.chatsHeaderMessageCount$.next(
                            params.data,
                        );
                        const opened = this.chatsService.opened$.value;
                        if (opened) {
                            return false;
                        }
                        this.chatsService.getChats$.next(true);
                    }

                    if (params.type === 'message_deleted') {
                        console.log(params.payload);
                        const newCnt = params.payload.header_message_count;
                        this.chatsService.chatsHeaderMessageCount$.next(newCnt);
                        this.chatsService.updateChatRoom$.next(params.payload);
                    }

                    if (params.event === 'chat_list_item') {
                        this.chatsService.chatsChatListItem$.next(params.data);
                    }

                    if (params.event === 'chat_list_item_counter') {
                        this.chatsService.chatsChatLIstItemCounter$.next(
                            params.data,
                        );
                    }

                    if (params.type === 'chat_list_item_is_read') {
                        this.chatsService.chatMessageIsRead$.next(
                            params.payload,
                        );
                    }
                }),
        );

        // экспорт из кайтена
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('kaiten_import')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    if (res) {
                        if (res['error']) {
                            this.notificationCenterService.handleError(
                                'Импорт не завершен, произошла ошибка',
                            );
                        } else {
                            this.notificationCenterService.handleSuccess(
                                'Импорт задач успешно завершен',
                            );
                        }
                    }
                }),
        );

        this.wsSubscriptions.push(
            this.wsService
                .on<any>('kaiten_import_status')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    if (res) {
                        this.notificationCenterService.handleSuccess(
                            res.message,
                        );
                    }
                }),
        );

        // смена тарифа
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('admin_tariff_info', 'tariff_started')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe(
                    (res) => {
                        this.pricingPlanChangeDate = moment.utc(
                            res.activation_date,
                        );
                        this.showMessage = true;
                        this.showMessageChanged = true;
                    },
                    (err) => {},
                ),
            this.wsService
                .on<any>('admin_tariff_info', 'tariff_expires')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe(
                    (res) => {
                        this.pricingPlanDate = moment.utc(res.end);
                        this.showMessage = true;
                    },
                    (err) => {},
                ),

            this.wsService
                .on<any>('user_tariff_info')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe(
                    (res) => {
                        this.tariffApi.tariffInfo$.next(res);
                    },
                    (err) => {},
                ),
        );

        // Удаляем чат из списка
        // this.wsSubscriptions.push(
        //     this.chatsService.deleteChat$
        //         .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
        //         .subscribe((data) => {
        //             if (data) {
        //                 this.wsService.send(
        //                     'delete',
        //                     {
        //                         entity: data['entity'] + '=' + data['id'],
        //                         time: new Date().getTime()
        //                     });
        //             }
        //         }));

        // Отправка хистори
        this.wsSubscriptions.push(
            this.chatsService.sendHistoryMessages$
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((data) => {
                    if (data) {
                        this.wsService.send('history', {
                            entity: data['entity'] + '=' + data['id'],
                            chat_id: data['chat_id'],
                            time: new Date().getTime(),
                        });
                    }
                }),
        );

        // Отписка от чата
        this.wsSubscriptions.push(
            this.chatsService.chatUnsubscribe$
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((data) => {
                    if (data) {
                        this.wsService.send('unsubscribe', {
                            entity: data['entity'] + '=' + data['id'],
                            time: new Date().getTime(),
                        });
                    }
                }),
        );

        // Встречи требующие внимания
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('meeting_attention_counter')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe(
                    (res) => {
                        if (res?.count) {
                            this.calServ.attentionCount.next(res.count);
                        }
                    },
                    (err) => {},
                ),
        );

        // Прочитать все
        this.wsSubscriptions.push(
            this.chatsService.readAll$
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((param) => {
                    if (param) {
                        this.wsService.send('readall');
                    }
                }),
        );

        // Отправка времени последнего прочтения
        this.wsSubscriptions.push(
            this.chatsService.sendLastRead$
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((data) => {
                    if (data) {
                        this.wsService.send('lastread', {
                            entity: data['entity'] + '=' + data['id'],
                            chat_id: data['chat_id'],
                            time: new Date().getTime(),
                        });
                    }
                }),
        );

        // Отправка сообщения
        this.wsSubscriptions.push(
            this.chatsService.sendChatMessage$
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((msg) => {
                    if (msg) {
                        this.wsService.send('message', msg);
                        this.chatsService.sendChatMessage$.next(null);
                    }
                }),
        );

        // Получение сообщения
        this.wsSubscriptions.push(
            this.wsService
                .on<ChatMessage>('message')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((message: ChatMessage) => {
                    this.chatsService.gotChatMessage$.next(message);
                }),
            this.wsService
                .on<ChatMessage>('message_edited')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((message: ChatMessage) => {
                    this.chatsService.editedChatMessage$.next(message);
                }),
            this.wsService
                .on<ChatMessage>('message_deleted')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((message: ChatMessage) => {
                    this.chatsService.deletedChatMessage$.next(message);
                }),
        );

        // Получение информации об обновлении уведомлений
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('notification')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    const notif = JSON.deserialize<NotificationCenterItem>(
                        res,
                        NotificationCenterItem,
                    );
                    this.notificationCenterService.updateNotificationItem$.next(
                        notif,
                    );
                }),
        );

        // Получение информации о количестве новых уведомлений
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('notification_count')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    const count = res['count'];
                    this.notificationCenterService.notificationsHeaderMessageCount$.next(
                        count,
                    );
                }),
        );

        // Получение информации о том, что уведомления были прочитаны
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('notification_read')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    const ids = res['events_ids'];
                    this.notificationCenterService.notificationsReadEvents$.next(
                        ids,
                    );
                }),
        );

        // Получение информации о количестве новых вопросов
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('learning_count')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    const count = res['count'];
                    this.notificationCenterService.learningCount$.next(count);
                }),
        );

        // Получение информации о создании матриц
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('matrix_create')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    this.matrixService.createdMatrixEvent$.next(res);
                }),
        );

        // Получение информации о количестве новых мотиваций
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('motivation_count')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    const count = res['count'];
                    this.notificationCenterService.motivationCount$.next(count);
                }),
        );

        this.wsSubscriptions.push(
            this.wsService
                .on<any>('meeting_attention_counter')
                .pipe(
                    takeUntil(this.destroyed),
                    takeUntil(this.wsClosed),
                    debounceTime(400),
                )
                .subscribe(
                    (res) => {
                        if (res) {
                            this.calServ.attentionCount.next(res?.count);
                        }
                    },
                    (err) => {},
                ),
        );

        // Получение информации о количестве новых мотиваций в ЛК
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('motivation_personal_cabinet_count')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    const count = res['count'];
                    this.notificationCenterService.motivationCabinetCount$.next(
                        count,
                    );
                }),
        );

        // Получение информации о количестве новых мотиваций в ЛК
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('matrix_personal_cabinet_count')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    const count = res['count'];
                    this.notificationCenterService.matrixCabinetCount$.next(
                        count,
                    );
                }),
        );

        // Получение информации о количестве новых матриц
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('matrix_count')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    const count = res['count'];
                    this.notificationCenterService.matrixCount$.next(count);
                }),
        );

        // Получение информации о количестве новых опросов
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('survey_count')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    const count = res['count'];
                    this.notificationCenterService.surveyCount$.next(count);
                }),
        );

        // Получение информации о количестве новых опросов
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('survey_update')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    const id = res['id'];
                    this.questionsService.surveyUpdate$.next(id);
                }),
        );

        // Получение информации о количестве новых вопросов
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('finance_counters')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    let count = 0;
                    count += res['documents'] ? res['documents'] : 0;
                    count += res['payment_application']
                        ? res['payment_application']
                        : 0;
                    count += res['smart_contract'] ? res['smart_contract'] : 0;
                    this.notificationCenterService.financeCount$.next(count);
                }),
        );

        // Получение информации о количестве новых задач
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('bug_tracker_count')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    const count = res['count'];
                    this.notificationCenterService.tasksCount$.next(count);
                }),
        );

        // Получение информации о количестве новых задач
        this.wsSubscriptions.push(
            this.wsService
                .on<any>('board_count')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    const count = res['count'];
                    this.notificationCenterService.boardsCount$.next(count);
                    if (res['count_per_board']) {
                        this.boardsService.countsPerBoard$.next(
                            res['count_per_board'],
                        );
                    }
                }),
        );

        this.wsSubscriptions.push(
            this.wsService
                .on<any>('pong')
                .pipe(takeUntil(this.destroyed), takeUntil(this.wsClosed))
                .subscribe((res: any) => {
                    this.actualVersion = res['version'];
                    if (this.firstOpening) {
                        this.setVersion(this.actualVersion);
                        this.firstOpening = false;
                    } else {
                        this.localVersion = this.getVersion();
                        if (
                            this.actualVersion &&
                            this.localVersion &&
                            this.localVersion !== this.actualVersion
                        ) {
                            this.versionIsDeprecated = true;
                            this.actualVersion = res['version'];
                        }
                    }
                    this.wsService.pingPong$.next(false);
                }),
        );
    }

    setVersion(ver) {
        localStorage.setItem('frontLocalVersion', ver);
    }

    getVersion() {
        return +localStorage.getItem('frontLocalVersion');
    }

    preloadChats() {
        this.chatsService
            .getChatsRoomsByTimestamp(this.auth.auth.apiKey, Date.now(), 30)
            .pipe(takeUntil(this.destroyed), debounceTime(500))
            .subscribe(
                (result) => {
                    if (result) {
                        const items = JSON.deserialize<ChatRoomsItem[]>(
                            result,
                            ChatRoomsItem,
                        );
                        this.chatsService.chatRooms$.next(items);
                        setTimeout(() => {
                            localStorage.setItem(
                                'savedChatRooms' + +this.auth.auth.id,
                                JSON.stringify(items),
                            );
                        });
                    }
                },
                (err) => {
                    // this.notificationCenterService.handleFullError(err);
                },
            );
    }

    getUsers() {
        const archiveObs = this.api.getUsersArchive();
        const usersObs = this.api.getUsers('');

        this.storage.users$.next([]);

        usersObs.pipe(takeUntil(this.destroyed)).subscribe(
            (res) => {
                const usrs = Array.isArray(res['users']) ? res['users'] : [];

                const list = this.storage.users$.getValue();
                list.push(...usrs);
                this.storage.users$.next(list);
            },
            (err) => {
                console.log(err);
                this.notificationCenterService.handleFullError(err);
            },
        );

        archiveObs.pipe(takeUntil(this.destroyed)).subscribe(
            (arch) => {
                const archs = arch ? arch : [];

                const list = this.storage.users$.getValue();
                list.push(...archs);
                this.storage.users$.next(list);
            },
            (err) => {
                this.notificationCenterService.handleFullError(err);
            },
        );
    }

    ngOnDestroy() {
        this.chatsService?.chatRooms$.next(null);
        this.wsService.disconnect();
        this.destroyed.next();
        this.destroyed.complete();
    }

    openChats(): void {
        this.activeSidebar =
            this.activeSidebar === this.SidebarType.CHATS
                ? null
                : this.SidebarType.CHATS;
    }

    openNotifications(): void {
        this.activeSidebar =
            this.activeSidebar === this.SidebarType.NOTIFICATIONS
                ? null
                : this.SidebarType.NOTIFICATIONS;
    }

    openQuestions(): void {
        this.activeSidebar =
            this.activeSidebar === this.SidebarType.QUESTIONS
                ? null
                : this.SidebarType.QUESTIONS;
    }

    hideMessage() {
        this.showMessage = false;
        this.showMessageChanged = false;

        this.api
            .setTariffMsgViewed('admin_tariff_info')
            .pipe(takeUntil(this.destroyed))
            .subscribe((resp) => {});
    }

    formatDate(date) {
        return moment.utc(date).format('DD.MM.YYYY');
    }

    formatDatePlusOne(date) {
        return moment.utc(date).add(1, 'day').format('DD.MM.YYYY');
    }
}
