<header class="header" *ngIf="isEmpty">
    <div class="title_wrap">
        <h2 class="title">
            <mat-icon class="icon" *ngIf="showIcon">attach_file</mat-icon> Вложения
        </h2>
        <div class="additional-text" *ngIf="additionalText">{{additionalText}}</div>
    </div>

    <app-button buttonType="add" *ngIf="!disabled" type="button" class="add-button learningFileuploadTask" (click)="openAddForm($event)"></app-button>
</header>

<mat-accordion class="fileupload" *ngIf="!isEmpty">
    <mat-expansion-panel class="fileupload-inner"
                         [expanded]="isOpened"
                         (closed)="isOpened = false"
                         [hideToggle]="isEmpty">
        <mat-expansion-panel-header class="fileupload__header" [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <div class="title_wrap">
                <h2 class="title">
                    <mat-icon class="icon" *ngIf="showIcon">attach_file</mat-icon> Вложения
                    <ng-container *ngIf="this.files && this.links">
                        ({{ this.files.length + this.links.length}})
                    </ng-container>
                </h2>
                <div class="additional-text" *ngIf="additionalText">{{additionalText}}</div>
            </div>
            <div *ngIf="!disabled" class="buttons__header">
                <app-button buttonType="add" *ngIf="!disabled" type="button" class="add-button-form learningRepeatFileuploadTask" (click)="openAddForm($event)"></app-button>
            </div>
        </mat-expansion-panel-header>

        <div class="images" *ngIf="files && files.length">
            <ng-container *ngFor="let item of files">
                <div class="images__item" *ngIf="isImage(item)" [ngClass]="{'large-task': largeTask}">
                    <img class="images__item-img"
                         [src]="getImageUrl(item)" >
                    <span class="image-cover _main"
                          *ngIf="item.id === cover && !disabled && taskId && canSetCover"
                          (click)="onCoverClick(item, $event)"
                          matTooltip="Удалить обложку">
                        <mat-icon class="icon">close</mat-icon>
                    </span>
                    <span class="images__item-overlay" (click)="openImage(item)">
                        <span class="image-cover"
                              *ngIf="!disabled && taskId && canSetCover"
                              (click)="onCoverClick(item, $event)"
                              matTooltip="Назначить обложкой"></span>

                        <a class="download-link"
                              [href]="getDownloadLink(item)"
                              target="_blank"
                              (click)="onDownloadLinkClick($event, item)"
                              matTooltip="Скачать">
                            <mat-icon [svgIcon]="'radius_download'" class="icon"></mat-icon>
                        </a>

                        <span class="txt">{{ item.fileName }}</span>

                        <span class="links__item-toolbar _image" *ngIf="!disabled">
                            <button mat-icon-button class="button _edit"
                                    (click)="openEditFormFile($event, item)">
                                <mat-icon class="icon _edit" svgIcon="radius_edit"></mat-icon>
                            </button>
                            <button mat-icon-button
                                    type="button"
                                    class="button _delete"
                                    (click)="onRemoveFileClick(item, $event)"
                                    title="Удалить фото">
                                <mat-icon class="icon _delete" svgIcon="radius_delete_fill"></mat-icon>
                            </button>
                        </span>
                    </span>

                </div>
            </ng-container>
        </div>
        <ul class="links" *ngIf="links && links.length">
            <ng-container *ngFor="let link of links, let index = index">
                <li class="links__item">
                    <mat-icon class="icon _link">link</mat-icon>
                    <a class="link" (click)="openLink($event, link)" [href]="link['url'] | safeUrl" target="_blank">
                        {{ link['name'] ? link['name'] : link['url'] }}
                    </a>
                    <div class="links__item-toolbar" *ngIf="!disabled">
                        <a mat-icon-button class="button _edit"
                                (click)="openEditFormLink($event, link)" autoFocus="false">
                            <mat-icon class="icon _edit" svgIcon="radius_edit"></mat-icon>
                        </a>

                        <button mat-icon-button
                                type="button"
                                class="button _delete"
                                (click)="onRemoveLinkClick(link, index)"
                                title="Удалить ссылку">
                            <mat-icon class="iten _delete" svgIcon="radius_delete_fill"></mat-icon>
                        </button>
                    </div>
                </li>
            </ng-container>
        </ul>

        <ul class="files" *ngIf="files && files.length > 0">
            <ng-container *ngFor="let item of files">
                <li class="files__item" *ngIf="isFile(item)" [ngClass]="{'_loaded': isLoaded(item)}">
                    <a *ngIf="isPdf(item)" class="link" [href]="isPdf(item) ? getFileUrlPdf(item) : null"
                       [target]="isPdf(item) ? '_blank' : null">
                        <img class="file__img"
                             [src]="getFileUrl(item)" (click)="downloadFile(item)">
                        <span class="txt"
                              [matTooltip]="item.fileName" (click)="downloadFile(item)">{{ item.fileName }}</span>
                    </a>

                    <ng-container *ngIf="!isPdf(item)">
                        <img class="file__img"
                             [src]="getFileUrl(item)" (click)="downloadFile(item)">
                        <span class="txt"
                              [matTooltip]="item.fileName" (click)="downloadFile(item)">{{ item.fileName }}</span>
                    </ng-container>

                    <div class="links__item-toolbar _files" *ngIf="!disabled">
                        <button mat-icon-button class="button _edit"
                                tabindex="2"
                                (click)="openEditFormFile($event, item)">
                            <mat-icon class="icon _edit" svgIcon="radius_edit"></mat-icon>
                        </button>
                        <button mat-icon-button
                                type="button"
                                class="button _delete"
                                tabindex="2"
                                (click)="onRemoveFileClick(item, $event)"
                                title="Удалить файл">
                            <mat-icon class="iten _delete" svgIcon="radius_delete_fill"></mat-icon>
                        </button>
                    </div>
                </li>
            </ng-container>
        </ul>
    </mat-expansion-panel>
</mat-accordion>

