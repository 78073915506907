import {Injectable} from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {NotifierService} from 'angular-notifier';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigQuestionsService extends ConfigService {

    constructor(notifierService: NotifierService) {
super(notifierService);
}

    readonly questions = environment.questions;
}
