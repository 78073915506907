import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ConfigService } from '../../../services/config.service';

@Injectable({
    providedIn: 'root',
})
export class AcademyConfigService extends ConfigService {
    /** API URL для получения списка тем **/
    readonly topics = environment.topics;
    readonly setting = environment.settingAcademy;
    readonly tests = environment.tests;
    readonly questions = environment.questionsAcademy;
    readonly assignments = environment.assignments;
    readonly answers = environment.answers;
    readonly questionHub = environment.questionHub;
    readonly userAcademy = environment.userAcademy;
    readonly users = environment.usersUrl;
    readonly rating = environment.ratingUrl;
    readonly statistics = environment.statistics;
    readonly platformLearning = environment.platformLearning;
}
