import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    settings = {
        'place': true,
        'protocol': true,
        'tasks': true,
        'tasksMode': 'one-day',
        'protocolApprove': true,
        'protocolPrint': true,
    };

    settingsChanged = new Subject();
    updateAttention = new BehaviorSubject(null);
    attentionCount = new BehaviorSubject(0);
    attentionList = new BehaviorSubject([]);
    closeNoticeable = new BehaviorSubject(false);

    hasPaidAccess = new BehaviorSubject(false);
    meetUsers = new BehaviorSubject([]);
    updateProtocolBoards$= new Subject();
    meet$= new BehaviorSubject(null);
    viewDate = new BehaviorSubject(new Date());

    hasPaidAccessToCalendar(): boolean {
        return !!this.hasPaidAccess.getValue();
    }

    saveSettings() {
        this.settings = {...this.settings};
        localStorage.setItem('calendar-settings', JSON.stringify(this.settings));
        this.settingsChanged.next(this.settings);
    }

    loadSettings() {
        const loadedStr = localStorage.getItem('calendar-settings');
        if (loadedStr) {
            const loadedObj = JSON.parse(loadedStr);

            // this.settings = {
            //     ...loadedObj,
            // }

            Object.keys(this.settings).map(key => {
                if (loadedObj[key] !== undefined && loadedObj[key] !== null) {
                    this.settings[key] = loadedObj[key];
                }
            });

            this.settingsChanged.next(this.settings);
        }
    }

    setPaidAccess(arr: string[]) {
        this.hasPaidAccess.next(arr?.includes('calendar'));
    }
}
